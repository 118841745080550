<template>
  <layout :active="3">
    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header card-header--flex">
                <h4 class="card-title">Balances</h4>
                <span v-bind:class="{ blink: hasBlink, 'wallet-id': true }" @click="copyWallet">
                  {{ wallet }}
                  <img
                    width="24"
                    style="fill: rgb(0, 0, 0)"
                    src="data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiNjY2NjY2MiPjxwYXRoIGQ9Ik0xMTIuODc1LDI2Ljg3NWgtMjYuODc1Yy0xMi43NzI4NCwtMC4wMDI2MSAtMjMuNzgyOTMsOC45ODUyMiAtMjYuMzM3NSwyMS41aC0wLjUzNzVjLTE0Ljg0MjY1LDAgLTI2Ljg3NSwxMi4wMzIzNSAtMjYuODc1LDI2Ljg3NXY0M2MwLDE0Ljg0MjY1IDEyLjAzMjM1LDI2Ljg3NSAyNi44NzUsMjYuODc1aDI2Ljg3NWMxMi43NzI4NCwwLjAwMjYxIDIzLjc4MjkzLC04Ljk4NTIyIDI2LjMzNzUsLTIxLjVoMC41Mzc1YzE0Ljg0MjY1LDAgMjYuODc1LC0xMi4wMzIzNSAyNi44NzUsLTI2Ljg3NXYtNDNjMCwtMTQuODQyNjUgLTEyLjAzMjM1LC0yNi44NzUgLTI2Ljg3NSwtMjYuODc1ek0xMDIuMTI1LDExOC4yNWMwLDguOTA1NTkgLTcuMjE5NDEsMTYuMTI1IC0xNi4xMjUsMTYuMTI1aC0yNi44NzVjLTguOTA1NTksMCAtMTYuMTI1LC03LjIxOTQxIC0xNi4xMjUsLTE2LjEyNXYtNDNjMCwtOC45MDU1OSA3LjIxOTQxLC0xNi4xMjUgMTYuMTI1LC0xNi4xMjVoMjYuODc1YzguOTA1NTksMCAxNi4xMjUsNy4yMTk0MSAxNi4xMjUsMTYuMTI1ek0xMjksOTYuNzVjMCw4LjkwNTU5IC03LjIxOTQxLDE2LjEyNSAtMTYuMTI1LDE2LjEyNXYtMzcuNjI1YzAsLTE0Ljg0MjY1IC0xMi4wMzIzNSwtMjYuODc1IC0yNi44NzUsLTI2Ljg3NWgtMTUuMjExMjVjMi4yNzg4NywtNi40NDU2MSA4LjM3NDY1LC0xMC43NTM1OCAxNS4yMTEyNSwtMTAuNzVoMjYuODc1YzguOTA1NTksMCAxNi4xMjUsNy4yMTk0MSAxNi4xMjUsMTYuMTI1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+"
                  />
                </span>
              </div>
              <div class="card-body">
                <div class="row align-items-center" style="height: 100%">
                  <div class="col-lg-6">
                    <div class="balance-chart_">
                      <!-- <div id="balance-chart"></div> -->
                      <balance-chart />
                      <h4 class="text-center">{{ totalCryptoBalance.toFixed(8) }} BTC</h4>
                      <h4 class="text-center">{{ balances_usdt.available.toFixed(2) }} USDT</h4>
                      <p class="text-center">Total: {{ (totalFiatBalance + balances_usdt.available).toFixed(2) }} $</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <perfect-scrollbar class="balance-widget">
                      <li>
                        <div class="icon-title">
                          <i class="cc BTC" style="color: #34c38f"></i>
                          <span>Available</span>
                        </div>
                        <div class="text-end">
                          <h5>{{ balances.available.toFixed(8) }} BTC</h5>
                          <h5>{{ balances_usdt.available.toFixed(2) }} {{ currency }}</h5>
                        </div>
                      </li>
                      <li>
                        <div class="icon-title">
                          <i class="cc BTC" style="color: rgb(85, 110, 230)"></i>
                          <span>Estimated</span>
                        </div>
                        <div class="text-end">
                          <h5>{{ balances.estimated.toFixed(8) }} BTC</h5>
                          <h5>{{ balances_usdt.estimated.toFixed(2) }} {{ currency }}</h5>
                        </div>
                      </li>
                      <li>
                        <div class="icon-title">
                          <i class="cc BTC" style="color: #f1b44c"></i>
                          <span>Pending</span>
                        </div>
                        <div class="text-end">
                          <h5>{{ balances.pending.toFixed(8) }} BTC</h5>
                          <h5>{{ balances_usdt.pending.toFixed(2) }} {{ currency }}</h5>
                        </div>
                      </li>
                      <li>
                        <div class="icon-title">
                          <i class="cc BTC" style="color: #f46a6a"></i>
                          <span>Locked</span>
                        </div>
                        <div class="text-end">
                          <h5>{{ balances.locked.toFixed(8) }} BTC</h5>
                          <h5>{{ balances_usdt.locked.toFixed(2) }} {{ currency }}</h5>
                        </div>
                      </li>
                    </perfect-scrollbar>
                  </div>
                  <div class="col col-12">
                    <button @click.prevent="openSendModal" type="button" class="btn btn-primary">Send Bitcoin</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xxl-6">
            <div class="card">
              <div class="intro-form-exchange intro-form-exchange--inner">
                <BuyForm />
              </div>
            </div>
          </div>

          <div class="col-xxl-6">
            <div class="card">
              <div class="intro-form-exchange intro-form-exchange--inner">
                <SwapForm />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="wallet-widget card">
              <h5>Available Balance</h5>
              <h2>
                <span class="text-success">{{ (fiatBalances.available + balances_usdt.available).toFixed(2) }}</span> <sub>$</sub>
              </h2>
              <!-- <p>= {{ balances.available.toFixed(8) }} BTC</p> -->
            </div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="wallet-widget card">
              <h5>Estimated Balance</h5>
              <h2>
                <span class="text-primary">{{ (fiatBalances.estimated + balances_usdt.estimated).toFixed(2) }}</span> <sub>$</sub>
              </h2>
              <!-- <p>= {{ balances.estimated.toFixed(8) }} BTC</p> -->
            </div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="wallet-widget card">
              <h5>Pending Balance</h5>
              <h2>
                <span class="text-warning">{{ (fiatBalances.pending + balances_usdt.pending).toFixed(2) }}</span> <sub>$</sub>
              </h2>
              <!-- <p>= {{ balances.pending.toFixed(8) }} BTC</p> -->
            </div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="wallet-widget card">
              <h5>Locked Balance</h5>
              <h2>
                <span class="text-danger">{{ (fiatBalances.locked + balances_usdt.locked).toFixed(2) }}</span> <sub>$</sub>
              </h2>
              <!-- <p>= {{ balances.locked.toFixed(8) }} BTC</p> -->
            </div>
          </div>
        </div>

        <div class="row">
          <div v-bind:class="[isVerifMessage ? 'col-xxl-6 col-xl-6 col-lg-12' : 'col-xxl-3 col-xl-3']">
            <div class="card">
              <div class="card-body">
                <div class="invite-content">
                  <h4>Invite a friend and get $30</h4>
                  <p>You will receive up to $30 when they: <br />1.Buy Crypto 2.Deposit Crypto 3. Finish Trading Tasks</p>
                  <div class="copy-link">
                    <form action="#">
                      <div class="input-group">
                        <input type="text" class="form-control" v-bind:value="message" />
                        <span class="input-group-text c-pointer" @click="doCopy">Copy</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-12" v-if="isVerifMessage">
            <div class="card">
              <div class="card-body">
                <div class="invite-content">
                  <h4>You need to pass personal verification</h4>
                  <p>Without verification you can`t buy <br />or withdraw cryptocurrency</p>
                  <router-link to="/settings-profile" class="btn btn-primary btn-danger">Start Now</router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[isVerifMessage ? 'col col-12' : 'col-xxl-9 col-xl-9']">
            <RecentTransactions />
          </div>
        </div>

        <div class="row">
          <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6" v-for="(priceData, index) in priceDatas" :key="index">
            <div class="price-widget" :class="`bg-${priceData.bg}`">
              <!-- <router-link to="price-details"> -->
              <div class="price-content">
                <div class="icon-title">
                  <i class="cc" :class="`${priceData.bg.toUpperCase()}-alt`"></i>
                  <span>{{ priceData.title }}</span>
                </div>
                <h5>{{ priceData.price && parseFloat(priceData.price).toFixed(2) }} $</h5>
              </div>
              <currency-chart />
              <!-- </router-link> -->
            </div>
          </div>
        </div>

        <div class="row" style="display: none">
          <div class="col-xxl-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Wallet Addresses</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped responsive-table">
                    <thead>
                      <tr>
                        <th>Coin Name</th>
                        <th>Address</th>
                        <th>QR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="coin-name">
                            <i class="cc BTC"></i>
                            <span>Bitcoin</span>
                          </div>
                        </td>
                        <td>35Hb5B6qJa5ntYaNFN3hGYXdAjh919g2VH</td>
                        <td>
                          <img class="qr-img" src="../../assets/images/qr.svg" alt="" width="40" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="coin-name">
                            <i class="cc BTC"></i>
                            <span>Bitcoin</span>
                          </div>
                        </td>
                        <td>35Hb5B6qJa5ntYaNFN3hGYXdAjh919g2VH</td>
                        <td>
                          <img class="qr-img" src="../../assets/images/qr.svg" alt="" width="40" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="coin-name">
                            <i class="cc BTC"></i>
                            <span>Bitcoin</span>
                          </div>
                        </td>
                        <td>35Hb5B6qJa5ntYaNFN3hGYXdAjh919g2VH</td>
                        <td>
                          <img class="qr-img" src="../../assets/images/qr.svg" alt="" width="40" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="coin-name">
                            <i class="cc BTC"></i>
                            <span>Bitcoin</span>
                          </div>
                        </td>
                        <td>35Hb5B6qJa5ntYaNFN3hGYXdAjh919g2VH</td>
                        <td>
                          <img class="qr-img" src="../../assets/images/qr.svg" alt="" width="40" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-6" style="display: none">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Balance</h4>
              </div>
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div class="balance-chart_">
                      <!-- <div id="balance-chart"></div> -->
                      <balance-chart />
                      <h4 class="text-center">Total Balance = $ 5360</h4>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <ul class="balance-widget">
                      <li>
                        <div class="icon-title">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </div>
                        <div class="text-end">
                          <h5>0.000242 BTC</h5>
                          <span>0.125 USD</span>
                        </div>
                      </li>
                      <li>
                        <div class="icon-title">
                          <i class="cc USDT"></i>
                          <span>Tether</span>
                        </div>
                        <div class="text-end">
                          <h5>0.000242 USDT</h5>
                          <span>0.125 USD</span>
                        </div>
                      </li>
                      <li>
                        <div class="icon-title">
                          <i class="cc XTZ"></i>
                          <span>Tezos</span>
                        </div>
                        <div class="text-end">
                          <h5>0.000242 XTZ</h5>
                          <span>0.125 USD</span>
                        </div>
                      </li>
                      <li>
                        <div class="icon-title">
                          <i class="cc XMR"></i>
                          <span>Monero</span>
                        </div>
                        <div class="text-end">
                          <h5>0.000242 XMR</h5>
                          <span>0.125 USD</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-6" style="display: none">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Balance</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped responsive-table">
                    <thead>
                      <tr>
                        <th>Asset</th>
                        <th>Balance</th>
                        <th>Available</th>
                        <th>Locked</th>
                        <th>% Gain</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>>0</td>
                        <td>0</td>
                        <td class="success-arrow">
                          <strong>0.005%</strong>
                          <i class="icofont-arrow-up ms-2"></i>
                        </td>
                      </tr>
                      <tr>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>>0</td>
                        <td>0</td>
                        <td class="success-arrow">
                          <strong>0.005%</strong>
                          <i class="icofont-arrow-up ms-2"></i>
                        </td>
                      </tr>
                      <tr>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>>0</td>
                        <td>0</td>
                        <td class="success-arrow">
                          <strong>0.005%</strong>
                          <i class="icofont-arrow-up ms-2"></i>
                        </td>
                      </tr>
                      <tr>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>>0</td>
                        <td>0</td>
                        <td class="success-arrow">
                          <strong>0.005%</strong>
                          <i class="icofont-arrow-up ms-2"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-6" style="display: none">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Deposit</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped responsive-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>12345</td>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>Jan 01</td>
                        <td>Pending</td>
                      </tr>
                      <tr>
                        <td>12345</td>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>Jan 01</td>
                        <td>Pending</td>
                      </tr>
                      <tr>
                        <td>12345</td>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>Jan 01</td>
                        <td>Pending</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-6" style="display: none">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Withdrawals</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped responsive-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>12345</td>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>Jan 01</td>
                        <td>Pending</td>
                      </tr>
                      <tr>
                        <td>12345</td>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>Jan 01</td>
                        <td>Pending</td>
                      </tr>
                      <tr>
                        <td>12345</td>
                        <td class="coin-name">
                          <i class="cc BTC"></i>
                          <span>Bitcoin</span>
                        </td>
                        <td>0</td>
                        <td>Jan 01</td>
                        <td>Pending</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal v-model="modalSend" hide-footer>
        <template #modal-header="{ close }">
          <h5 class="modal-title"></h5>
          <b-button class="btn-close" data-dismiss="modal" aria-label="Close" @click="close()"> </b-button>
        </template>
        <template>
          <div>
            <form @submit.prevent="" class="identity-upload">
              <div class="identity-content overlay" v-if="showFormOverlay">
                <i class="icofont-spinner anim-rotate" v-if="isFormLoading"></i>
                <div v-if="!isFormLoading">
                  <span class="icon"><i class="icofont-check"></i></span>
                  <h4>
                    <span>Your transaction sent to processing</span>
                  </h4>
                  <p>Please wait until confirmations was done</p>
                </div>
              </div>
              <div class="identity-content">
                <h4>
                  <br />
                  <span>Send Bitcoin</span>
                </h4>
                <p>Please send BTC to the right network</p>
              </div>
            </form>
            <div class="modal-body">
              <form @submit.prevent="onBTCSendSubmit">
                <label class="form-label">Choose Network</label>
                <div class="input-group">
                  <div style="width: 100%">
                    <select class="form-control" name="method">
                      <option value="BTC" selected>Bitcoin (SegWit)</option>
                      <option value="BTC_ERC">Bitcoin (ERC20)</option>
                      <option value="BTC_BEP20">Bitcoin (BEP20)</option>
                    </select>
                    <div class="input-icon">
                      <i class="icofont-caret-down"></i>
                    </div>
                  </div>
                </div>
                <br />
                <label class="form-label">Recepient Wallet</label>
                <div class="input-group">
                  <div style="width: 100%">
                    <input
                      type="text"
                      pattern="^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,59}$"
                      v-model="sendBTCForm.recepient"
                      name="recepient"
                      class="form-control"
                      minlength="20"
                      required
                    />
                    <span style="line-height: 2" v-if="sendBTCFormError != ''" class="text-danger">{{ sendBTCFormError }}</span>
                  </div>
                </div>
                <br />
                <label class="form-label">Amount</label>
                <div class="input-group">
                  <div style="width: 100%">
                    <input type="number" name="amount" class="form-control" v-model="sendBTCForm.amount" min="0.00000001" :max="balances.available" step="0.00000001" />
                  </div>
                </div>
                <span style="line-height: 2">Available amount: {{ balances.available }}</span>
                <br /><br />
                <button type="submit" class="btn btn-primary">Send Bitcoin</button>
              </form>
            </div>
          </div>
        </template>
      </b-modal>
    </div>
  </layout>
</template>

<script>
import CurrencyChart from '../../components/qCash/CurrencyChart.vue'
import BalanceChart from '../../components/qCash/BalanceChart.vue'
import Layout from '../../components/qCash/Layout.vue'
import BuyForm from '../../components/BuyForm/BuyForm.vue'
import SwapForm from '../../components/SwapForm/SwapForm.vue'
import RecentTransactions from '../../components/RecentTransactions.vue'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

export default {
  components: { Layout, BuyForm, SwapForm, BalanceChart, CurrencyChart, RecentTransactions },
  data() {
    return {
      message: 'https://www.buybit.one/signup?ref_id=29387bx92837e92dnasdh32dx',
      modalSend: false,
      showFormOverlay: false,
      isFormLoading: false,
      sendBTCFormError: '',
      userID: '',
      sendBTCForm: {
        amount: 0,
        recepient: ''
      },
      priceDatas: [
        { bg: 'btc', title: 'Bitcoin', pair: 'BTCUSDT' },
        { bg: 'eth', title: 'Ethereum', pair: 'ETHUSDT' },
        { bg: 'ltc', title: 'Litecoin', pair: 'LTCUSDT' }
      ],
      cryptoPriceData: [],
      filteredPairs: [],
      currency: 'USDT',
      wallet: '',
      btc_price: 0,
      balances: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0
      },
      balances_usdt: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0
      },
      fiatBalances: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0
      },
      recepientBalances: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0
      },
      verification: '',
      isVerifMessage: false,
      hasBlink: false,
      usdt_balance: 0
    }
  },
  watch: {
    balances: () => {
      this.updateFiatBalances()
    }
  },
  computed: {
    totalCryptoBalance: function () {
      return this.balances.available + this.balances.pending + this.balances.locked + this.balances.estimated
    },
    totalFiatBalance: function () {
      return this.totalCryptoBalance * this.btc_price
    }
  },
  mounted: function () {
    this.getCryptoPairsPrice()
  },
  created() {
    if (firebase.auth().currentUser) {
      // console.log(firebase.auth().currentUser)

      db.collection('users')
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((doc) => {
          this.verification = doc.data().verification
          this.showVerifMessage(doc.data().verification)
          this.getBtcPrice()

          if (doc.data().balances) {
            this.balances.available = doc.data().balances.available
            this.balances.estimated = doc.data().balances.estimated
            this.balances.pending = doc.data().balances.pending
            this.balances.locked = doc.data().balances.locked
          }

          if (doc.data().balances_usdt) {
            this.balances_usdt.available = doc.data().balances_usdt.available
            this.balances_usdt.estimated = doc.data().balances_usdt.estimated
            this.balances_usdt.pending = doc.data().balances_usdt.pending
            this.balances_usdt.locked = doc.data().balances_usdt.locked
          }

          this.wallet = doc.data().wallet
        })
    }
  },
  methods: {
    sendTransactionToUser: function (wallet) {
      db.collection('users')
        .get()
        .then((users) => {
          const snapshot = users.docs.map((doc) => doc.data())
          console.log('Users: ', snapshot)

          const user = snapshot.filter((user) => user.wallet == wallet)
          console.log('Recepient: ', wallet)
          console.log('User: ', user[0].uid)
          return user[0].uid

          // if (doc.exists) {
          //     (this.recepientBalances.available = doc.data().balances.available),
          //     (this.recepientBalances.pending = doc.data().balances.pending),
          //     (this.recepientBalances.estimated = doc.data().balances.estimated),
          //     (this.recepientBalances.locked = doc.data().balances.locked)
          // } else {
          //   this.sendBTCFormError = 'No such user exist'
          //   throw new Error('No such user exist!')
          // }
        })
        .then((uid) => {
          console.log('Sending BTC to the Recepient...')
          //console.log(this.recepientBalances)
          db.collection('users')
            .doc(uid)
            .collection('transactions')
            .add({
              amount: parseFloat(this.sendBTCForm.amount),
              currency: 'BTC',
              date: firebase.firestore.Timestamp.now(),
              status: 'Awaiting confirmations (1/6)',
              type: 'in',
              new_transaction: true
            })
            .then(() => {
              db.collection('users')
                .doc(uid)
                .update({
                  balances: {
                    available: parseFloat(this.recepientBalances.available + this.sendBTCForm.amount),
                    pending: this.recepientBalances.pending,
                    estimated: this.recepientBalances.estimated,
                    locked: this.recepientBalances.locked
                  }
                })
                .then(() => {
                  console.log('BTC successfully sent!')
                  this.isFormLoading = false
                })
                .catch((e) => {
                  console.log(e.message)
                })
            })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    onBTCSendSubmit: function () {
      this.isFormLoading = true
      this.showFormOverlay = true

      db.collection('users')
        .doc(firebase.auth().currentUser.uid)
        .collection('transactions')
        .add({
          amount: parseFloat(this.sendBTCForm.amount),
          currency: 'BTC',
          date: firebase.firestore.Timestamp.now(),
          status: 'Awaiting confirmations (0/6)',
          type: 'out',
          hash: this.randomString(64)
        })
        .then((doc) => {
          this.updateTransactionStatus(doc.id, 20000, 7)
        })
        .then(() => {
          db.collection('users')
            .doc(firebase.auth().currentUser.uid)
            .update({
              balances: {
                available: parseFloat(this.balances.available - this.sendBTCForm.amount),
                pending: this.balances.pending,
                estimated: this.balances.estimated,
                locked: this.balances.locked
              }
            })
            .then(() => {
              console.log('Balances updated')
              this.isFormLoading = false
            })
            .catch((e) => {
              console.log(e)
            })
        })
        .then(this.setCustomTimeout(20000))
        .then(() => {
          console.log('Transaction sending started')
          this.sendTransactionToUser(this.sendBTCForm.recepient)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    randomString: function (lng) {
      var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'
      var string_length = lng
      var randomstring = ''
      for (var i = 0; i < string_length; i++) {
        var rnum = Math.floor(Math.random() * chars.length)
        randomstring += chars.substring(rnum, rnum + 1)
      }
      return randomstring
    },
    setCustomTimeout: function (ms) {
      return function (x) {
        return new Promise((resolve) => setTimeout(() => resolve(x), ms))
      }
    },
    updateTransactionStatus: function (id, delay, repetitions) {
      var x = 0
      var intervalID = window.setInterval(function () {
        // callback()

        db.collection('users')
          .doc(firebase.auth().currentUser.uid)
          .collection('transactions')
          .doc(id)
          .update({
            status: `Awaiting confirmations (${x}/6)`
          })

        if (++x === repetitions) {
          window.clearInterval(intervalID)

          db.collection('users').doc(firebase.auth().currentUser.uid).collection('transactions').doc(id).update({
            status: `Confirmed`
          })
        }
      }, delay)
    },
    openSendModal: function () {
      this.modalSend = true

      this.sendBTCForm.amount = this.balances.available
    },
    showVerifMessage: function (status) {
      console.log(status)
      if (status !== 'Verified') {
        this.isVerifMessage = true
      } else {
        this.isVerifMessage = false
      }
    },
    doCopy: function () {
      this.$copyText(this.message).then(
        function () {
          console.log('Copied')
        },
        function () {
          alert('Can`t copy')
        }
      )
    },
    copyWallet: function () {
      this.hasBlink = true
      this.$copyText(this.wallet).then(
        function () {
          console.log('Copied')
        },
        function () {
          alert('Can`t copy')
        }
      )
      setTimeout(() => {
        this.hasBlink = false
      }, '200')
    },
    getBtcPrice: function () {
      fetch(`https://api.binance.com/api/v3/ticker/price?symbol=BTC${this.currency ? this.currency : 'USDT'}`)
        .then((response) => response.json())
        .then((data) => {
          this.btc_price = parseFloat(data.price)
          console.log(this.btc_price)
          this.updateFiatBalances()
        })
    },
    updateFiatBalances: function () {
      this.fiatBalances.available = this.btc_price * this.balances.available
      this.fiatBalances.estimated = this.btc_price * this.balances.estimated
      this.fiatBalances.pending = this.btc_price * this.balances.pending
      this.fiatBalances.locked = this.btc_price * this.balances.locked

      // return (this.balance.fiat = newAmount.toFixed(2))
    },
    getCryptoPairsPrice: function () {
      fetch(`https://api.binance.com/api/v3/ticker/price`)
        .then((response) => response.json())
        .then((data) => {
          this.cryptoPriceData = data
          return data
        })
        .then((data) => {
          this.setCryptoPairsPrice(data)
        })
        .then(() => {
          this.mergeArrays()
        })
    },
    setCryptoPairsPrice: function (data) {
      let pairs = []
      this.priceDatas.forEach(function (coin) {
        const pair = data.find((pair) => pair.symbol == coin.pair)
        pairs.push(pair)
      })
      this.filteredPairs = pairs
    },
    mergeArrays: function () {
      let filteredPairs = this.filteredPairs
      let datas = this.priceDatas.map((x, i) => {
        x.price = filteredPairs[i].price
        return x
      })
      this.priceDatas = datas
    }
  }
}
</script>
<style scoped>
.blink {
  transition: opacity 0.1s ease-in-out;
  opacity: 0.4;
}
.wallet-id {
  font-size: 16px;
  cursor: pointer;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.input-icon:active {
  opacity: 0.7;
}
.form-control:disabled {
  background: transparent;
  cursor: text;
}
.intro-form-exchange .input-group select {
  padding-left: 15px;
}
</style>