<template>
  <form action="post" @submit.prevent="submitFormStep1">
    <div class="row g-3">
      <div class="col-12">
        <h3 class="form-title">Buy Bitcoin</h3>
        <label class="form-label">You give</label>
        <div class="input-group">
          <div class="curr-icon curr-icon-fiat" v-if="currency == 'EUR'">
            <i class="icofont-euro"></i>
          </div>
          <div class="curr-icon curr-icon-fiat" v-if="currency == 'USDT'">
            <i class="cc USDT" style="color: #fff"></i>
          </div>
          <select class="form-control" name="method" v-model="currency">
            <option value="USDT">USDT</option>
            <!-- <option value="EUR">EUR</option> -->
          </select>
          <input type="number" min="1000" step="0.01" max="10000000" name="currency_amount" class="form-control" placeholder="Fill amount" v-model="fiat_amount" required />
        </div>
      </div>

      <div class="col-12">
        <label class="form-label">You get</label>
        <div class="input-group">
          <div class="curr-icon curr-icon-btc">
            <i class="icofont-bitcoin"></i>
          </div>
          <select class="form-control" name="method" disabled>
            <option value="bank">BTC</option>
          </select>
          <input type="number" min="0.1" step="0.00000001" max="1000" name="currency_amount" class="form-control" placeholder="Fill amount" v-model="crypto_amount" required />
        </div>
      </div>

      <div class="col-12">
        <label class="form-label">Discount code</label>
        <div class="input-group">
          <div style="width: 100%">
            <input type="text" name="discount_code" class="form-control" placeholder="Fill Discount Code" v-model="discount_code" />
            <div v-if="isDiscountEnabled" class="discount-approve" width="24" height="24">
              <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48">
                <circle fill="#4CAF50" cx="24" cy="24" r="21" />
                <polygon fill="#CCFF90" points="34.6,14.6 21,28.2 15.4,22.6 12.6,25.4 21,33.8 37.4,17.4" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="form-info">
        <div class="flex-between">
          <span class="item-left">BTC Price:</span>
          <span class="item-right big" v-bind:class="{ line: isDiscountEnabled }">{{ price }} {{ currency == 'USDT' ? '$' : '€' }}</span>
        </div>
        <div class="flex-between" v-if="isDiscountEnabled">
          <span class="item-left">Price with discount:</span>
          <span class="item-right big">{{ discount_price }} {{ currency == 'USDT' ? '$' : '€' }}</span>
        </div>
        <div class="flex-between" v-if="isDiscountEnabled">
          <span class="item-left">Your discount:</span>
          <span class="item-right big">{{ discount_percent }}% ({{ discount_amount }} {{ currency == 'USDT' ? '$' : '€' }})</span>
        </div>
        <div class="flex-between">
          <span class="item-left">You will get:</span>
          <span class="item-right big">{{ crypto_amount }} BTC</span>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">Buy Now</button>

      <div class="text-center">Time to fix price - 00:0{{ countDown }}</div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BuyFormStep2',
  data: function () {
    return {
      discount_code: '',
      discount_percent: 9,
      isDiscountEnabled: false,
      discount_price: 0,
      discount_amount: 0,
      price: 0,
      currency: 'USDT',
      fiat_amount: localStorage.fiatAmout ? localStorage.fiatAmout : 1000,
      crypto_amount: 0,
      countDown: 9,
      total: 0
    }
  },
  mounted: function () {
    this.countDownTimer()
    this.getBtcPrice()

    localStorage.currency = this.currency;

    // if (localStorage.currency) {
    //   this.currency = localStorage.currency
    // }

    // if (localStorage.discountCode) {
    //   this.discount_code = localStorage.discountCode
    // }

    setInterval(() => {
      this.getBtcPrice()
    }, this.countDown * 1000)
  },
  watch: {
    currency: function () {
      this.getBtcPrice()
      localStorage.currency = this.currency
    },
    price: function () {
      this.updateCryptoAmount()
      this.updateDiscountPrice()
    },
    fiat_amount: function () {
      this.updateCryptoAmount()
      this.updateDiscountAmount()
    },
    crypto_amount: function () {
      this.updateFiatAmount()
      this.updateDiscountAmount()
    },
    discount_code: function () {
      this.updateDiscountState()
      this.updateDiscountPrice()
      this.updateFiatAmount()
    },
    countDown: function () {
      if (this.countDown == 0) {
        this.countDown = 9
        this.countDownTimer()
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    submitFormStep1: function () {
      localStorage.discountCode = this.discount_code
      localStorage.fiatAmout = this.fiat_amount
      localStorage.discountAmout = this.discount_amount
      localStorage.isDiscountEnabled = this.isDiscountEnabled
      localStorage.currency = this.currency

      if (!this.user.loggedIn) {
        this.$router.push('/signin')
      } else {
          this.$emit('form-step', '2')
       
        //this.$emit('form-step', '3')
      }
    },
    countDownTimer: function () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    getBtcPrice: function () {
      fetch(`https://api.binance.com/api/v3/ticker/price?symbol=BTC${this.currency}`)
        .then((response) => response.json())
        .then((data) => (this.price = parseFloat(data.price)))
    },
    updateFiatAmount: function () {
      let amount = 0

      if (this.isDiscountEnabled) {
        amount = this.discount_price * this.crypto_amount
      } else {
        amount = this.price * this.crypto_amount
      }

      const newAmount = Math.round(amount * 1e2) / 1e2
      return (this.fiat_amount = newAmount)
    },
    updateCryptoAmount: function () {
      let amount = 0

      if (this.isDiscountEnabled) {
        amount = this.fiat_amount / this.discount_price
      } else {
        amount = this.fiat_amount / this.price
      }

      const newAmount = Math.round(amount * 1e8) / 1e8
      return (this.crypto_amount = newAmount)
    },
    updateDiscountPrice: function () {
      let price = this.price - (this.price * this.discount_percent) / 100
      return (this.discount_price = Math.round(price * 1e2) / 1e2)
    },
    updateDiscountAmount: function () {
      if (this.isDiscountEnabled) {
        let amount = (this.fiat_amount * this.discount_percent) / 100
        return (this.discount_amount = Math.round(amount * 1e2) / 1e2)
      }
    },
    updateDiscountState: function () {
      console.log(this.crypto_amount)
      if (this.discount_code == 'JDH28D98') { // OLD D7G23N8O
        // this.crypto_amount = 20
        return (this.isDiscountEnabled = true)
      }
      return (this.isDiscountEnabled = false)
    }
  }
}
</script>