<template>
  <div id="main-wrapper" class="show">
    <div class="authincation section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-5 col-md-6">
            <auth-header />

            <div class="auth-form card">
              <div class="identity-content card-hover-message" v-if="isFormSubmitted">
                <span class="icon"><i class="icofont-email"></i></span>
                <p class="big">
                  We sent verification email to the <strong class="text-dark">{{ form.email }}</strong
                  >. Click the link inside email to proceed password change.
                </p>
              </div>

              <div class="card-body">
                <h3 class="card-title text-center mt-3">Reset password</h3>
                <p class="text-center text-error">{{ error }}</p>

                <form @submit.prevent="onSubmit" class="row g-3">
                  <div class="col-12">
                    <label class="form-label">Email</label>
                    <input type="text" class="form-control" placeholder="Your email" v-model="form.email" />
                  </div>
                  <div class="text-center mt-4">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                  </div>
                </form>
              </div>
            </div>

            <!-- <div class="text-center new-account mt-3">
              <p>
                Don't get code?
                <router-link class="text-primary" to="otp-1">Resend</router-link>
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthHeader from '../../components/qCash/AuthHeader.vue'
import firebase from 'firebase/compat/app'

export default {
  components: { AuthHeader },
  data() {
    return {
      form: {
        email: ''
      },
      isFormSubmitted: false,
      error: null
    }
  },
  methods: {
    onSubmit() {
      let actionCodeSettings = {
        url: 'https://buybit.one/reset-confirm/'
      }

      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email, actionCodeSettings)
        .then(() => {
          console.log('Password reset email sent.')
          this.isFormSubmitted = true
        })
        .catch((err) => {
          this.error = err.code
        })
    }
  }
}
</script>
