<template>
  <div class="sidebar">
    <div class="brand-logo">
      <router-link to="/"><img src="../../assets/images/svg/buybit-logo-no-text.svg" alt="BuyBit" /> </router-link>
    </div>
    <div class="menu">
      <ul>
        <li>
          <router-link to="wallet" data-toggle="tooltip" data-placement="right" title="Wallet" :class="active === 3 && 'active'">
            <span><i class="icofont-wallet"></i></span>
          </router-link>
        </li>

        <li>
          <router-link to="price" data-toggle="tooltip" data-placement="right" title="Price" :class="active === 4 && 'active'">
            <span><i class="icofont-price"></i></span>
          </router-link>
        </li>

        <li>
          <router-link to="settings-profile" data-toggle="tooltip" data-placement="right" title="Price" :class="active === 5 && 'active'">
            <span><i class="icofont-ui-settings"></i></span>
          </router-link>
        </li>

        <!-- <br />

        <li>
          <router-link to="/dashboard" data-toggle="tooltip" data-placement="right" title="Home" :class="active === 1 && 'active'">
            <span><i class="icofont-ui-home"></i></span>
          </router-link>
        </li>

        <li>
          <router-link to="trade" data-toggle="tooltip" data-placement="right" title="Trade" :class="active === 2 && 'active'">
            <span><i class="icofont-stack-exchange"></i></span>
          </router-link>
        </li> -->

        <li class="logout" @click="signOut">
          <router-link to="signin" data-toggle="tooltip" data-placement="right" title="Signout">
            <span><i class="icofont-power"></i></span>
          </router-link>
        </li>
      </ul>

      <!-- <p class="copyright">&#169; <router-link to="#">codeefly</router-link></p> -->
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import store from '../../store'

export default {
  name: 'Sidebar',
  props: {
    active: Number
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/')
          store.commit('SET_LOGGED_IN', false)
        })
        .catch((error) => {
          console.log(error.message)
          this.$router.push('/')
        })
    }
  }
}
</script>
