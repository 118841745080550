<template>
  <form action="POST" @submit.prevent="submitFormStep2">
    <div class="row g-3">
      <div class="col-12">
        <div class="title-flex">
          <div class="back-btn" @click="$emit('form-step', '1')">
            <svg class="text-main" fill="currentColor" width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1.645L7.28745 0L0 7L7.28745 14L9 12.355L3.43725 7L9 1.645Z"></path>
            </svg>
          </div>
          <h3 class="form-title">Wallet & Payment Method</h3>
        </div>
        <div class="subtitle-small">
          <p>You can choose BuyBit Private Wallet or BuyBit Trading Wallet to receive crypto</p>
        </div>
        <label class="form-label">Choose wallet</label>
        <div class="wallet-address-input">
          <div class="input-group">
            <div class="curr-icon curr-icon-btc">
              <i class="icofont-bitcoin"></i>
            </div>
            <select class="form-control" name="method">
              <option value="private-wallet">Your Private BTC Wallet</option>
              <option value="trading-wallet">Your Trading BTC Wallet</option>
            </select>
          </div>
          <div class="imput-row" style="margin-top:20px">
            <label class="form-label">Payment method</label>
            <select class="form-control" name="payment">
              <option value="bank-accountt">USDT Transfer</option>
              <!-- <option value="bank-accountt">Bank Account Transfer</option> -->
            </select>
          </div>
          <!-- <input type="text" name="wallet_address" class="form-control" placeholder="Fill wallet adress" minlength="20" v-model="wallet" required /> -->
        </div>
      </div>

      <!-- <div class="form-info">
        <div class="flex-between">
          <span class="item-left">BTC Price:</span>
          <span class="item-right big" v-bind:class="{ line: isDiscountEnabled }">{{ price }} {{ currency == 'USDT' ? '$' : '€' }}</span>
        </div>
        <div class="flex-between" v-if="isDiscountEnabled">
          <span class="item-left">Your discount:</span>
          <span class="item-right big">{{ discount_percent }}% ({{ discount_amount }} {{ currency == 'USDT' ? '$' : '€' }})</span>
        </div>
        <div class="flex-between" v-if="isDiscountEnabled">
          <span class="item-left">Price with discount:</span>
          <span class="item-right big">{{ discount_price }} {{ currency == 'USDT' ? '$' : '€' }}</span>
        </div>
        <div class="flex-between">
          <span class="item-left">You will get:</span>
          <span class="item-right big">{{ crypto_amount }} BTC</span>
        </div>
      </div> -->

      <button type="submit" class="btn btn-primary" @click.prevent="showErrorModal()" v-if="!isVerified">Confirm</button>
      <button type="submit" class="btn btn-primary" v-if="isVerified">Confirm</button>

      <!-- <div class="text-center">Time to fix price - 00:0{{ countDown }}</div> -->

      <b-modal v-model="modalShowError" hide-footer>
        <template #modal-header="{ close }">
          <h5 class="modal-title"></h5>
          <b-button class="btn-close" data-dismiss="modal" aria-label="Close" @click="close()"> </b-button>
        </template>
        <template>
          <div class="auth-form">
            <form @submit.prevent="" class="identity-upload">
              <div class="identity-content">
                <span class="icon icon-error"><i class="icofont-error"></i></span>
                <h4>Your account is no verified</h4>
                <p>Please submit verification in the profile settings</p>
                <router-link to="/settings-profile" class="btn btn-primary">Go to Verification</router-link>
              </div>
            </form>
          </div>
        </template>
      </b-modal>
    </div>
  </form>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

export default {
  name: 'BuyFormStep2',
  data: function () {
    return {
      discount_code: '',
      discount_percent: 17,
      isDiscountEnabled: false,
      discount_price: 0,
      discount_amount: 0,
      price: 0,
      currency: 'USDT',
      fiat_amount: 1000,
      crypto_amount: 0,
      countDown: 9,
      total: 0,
      wallet: '',
      verification: '',
      isVerified: false,
      modalShowError: false
    }
  },
  mounted: function () {
    this.countDownTimer()
    this.getBtcPrice()

    if (localStorage.currency) {
      this.currency = localStorage.currency
    }

    if (localStorage.isDiscountEnabled) {
      this.isDiscountEnabled = localStorage.isDiscountEnabled === "true"
    }

    // if (localStorage.wallet) {
    //   this.wallet = localStorage.wallet
    // }

    setInterval(() => {
      this.getBtcPrice()
    }, this.countDown * 1000)
  },
  watch: {
    currency: function () {
      this.getBtcPrice()
    },
    price: function () {
      this.updateCryptoAmount()
      this.updateDiscountPrice()
    },
    fiat_amount: function () {
      this.updateCryptoAmount()
      this.updateDiscountAmount()
    },
    crypto_amount: function () {
      this.updateFiatAmount()
      this.updateDiscountAmount()
    },
    discount_code: function () {
      this.updateDiscountState()
      this.updateDiscountPrice()
      this.updateCryptoAmount()
    },
    countDown: function () {
      if (this.countDown == 0) {
        this.countDown = 9
        this.countDownTimer()
      }
    }
  },
  methods: {
    submitFormStep2: function () {
      localStorage.wallet = this.wallet

      this.$emit('form-step', '4')
    },
    showErrorModal: function () {
      this.modalShowError = true
    },
    countDownTimer: function () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    getBtcPrice: function () {
      fetch(`https://api.binance.com/api/v3/ticker/price?symbol=BTC${this.currency}`)
        .then((response) => response.json())
        .then((data) => (this.price = parseFloat(data.price)))
    },
    updateFiatAmount: function () {
      let amount = 0

      if (this.isDiscountEnabled) {
        amount = this.discount_price * this.crypto_amount
      } else {
        amount = this.price * this.crypto_amount
      }

      const newAmount = Math.round(amount * 1e2) / 1e2
      return (this.fiat_amount = newAmount)
    },
    updateCryptoAmount: function () {
      let amount = 0

      if (this.isDiscountEnabled) {
        amount = this.fiat_amount / this.discount_price
      } else {
        amount = this.fiat_amount / this.price
      }

      const newAmount = Math.round(amount * 1e8) / 1e8
      return (this.crypto_amount = newAmount)
    },
    updateDiscountPrice: function () {
      let price = this.price - (this.price * this.discount_percent) / 100
      return (this.discount_price = Math.round(price * 1e2) / 1e2)
    },
    updateDiscountAmount: function () {
      if (this.isDiscountEnabled) {
        let amount = (this.fiat_amount * this.discount_percent) / 100
        return (this.discount_amount = Math.round(amount * 1e2) / 1e2)
      }
    },
    updateDiscountState: function () {
      if (this.discount_code == 'D7G23N8O') {
        return (this.isDiscountEnabled = true)
      }
      return (this.isDiscountEnabled = false)
    }
  },
  created() {
    db.collection('users')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot((doc) => {
        this.verification = doc.data().verification

        if (this.verification == 'Verified') {
          this.isVerified = true
        } else {
          this.isVerified = false
        }
      })
  }
}
</script>

<style scoped>
.title-flex {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}
.title-flex .form-title {
  margin: 0;
}
.back-btn {
  display: inline-block;
  margin-right: 10px;
  padding: 5px;
  cursor: pointer;
}
.intro-form-exchange .wallet-address-input .form-control {
  font-size: 16px;
  font-weight: 500;
}
.intro-form-exchange .input-group select {
  flex-grow: 1;
}
</style>