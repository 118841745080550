<template>
  <div id="main-wrapper" class="show">
    <div class="authincation section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-5 col-md-6">
            <auth-header :title="'Create account'" />
            <div class="auth-form card">
              <div class="card-body">
                <h3 class="card-title text-center mt-3">Create account</h3>

                <p class="text-center text-error">{{ error }}</p>

                <form method="post" @submit.prevent="onSubmit" name="myform" class="signin_validate row g-3">
                  <div class="col-12">
                    <input type="text" class="form-control" placeholder="Name" name="name" v-model="form.name" required />
                  </div>
                  <div class="col-12">
                    <input type="email" class="form-control" placeholder="hello@example.com" name="email" v-model="form.email" required />
                  </div>
                  <div class="col-12">
                    <input type="password" class="form-control" placeholder="Password" name="password" v-model="form.password" required />
                  </div>
                  <div class="col-12">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" required />
                      <label class="form-check-label" for="flexSwitchCheckDefault">
                        I certify that I am 18 years of age or older.
                        <!-- <router-link to="#" class="text-primary">User Agreement</router-link> and <router-link to="#" class="text-primary">Privacy Policy</router-link>. -->
                      </label>
                    </div>
                  </div>

                  <div class="text-center">
                    <button type="submit" class="btn btn-primary btn-block">Create account</button>
                  </div>
                </form>
              </div>
            </div>

            <div class="text-center">
              <p class="mt-3 mb-0"><router-link class="text-primary" to="signin">Sign in</router-link> to your account</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthHeader from '../../components/qCash/AuthHeader.vue'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

export default {
  components: { AuthHeader },
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: ''
      },
      error: null,
      wallet: ''
    }
  },
  created() {
    this.getWallet()
  },
  methods: {
    getWallet() {
      fetch('https://blockchain.info/unconfirmed-transactions?format=json')
        .then((response) => response.json())
        .then((data) => data.txs.filter((tx) => tx.out[0].addr && tx.out[0].addr.startsWith('bc1')))
        .then((filtered) => (filtered.length > 1 ? (this.wallet = filtered[0].out[0].addr) : console.log('Not found wallet!')))
        //.then((filtered) => console.log(filtered))
    },
    onSubmit() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          const dataBase = db.collection('users').doc(data.user.uid)

          dataBase.set({
            name: this.form.name,
            email: this.form.email,
            password: this.form.password,
            verification: 'No Verified',
            wallet: this.wallet,
            regDate: firebase.firestore.Timestamp.fromDate(new Date()),
            uid: data.user.uid,
            balances: {
              available: 0,
              pending: 0,
              locked: 0,
              estimated: 0
            },
            balances_usdt: {
              available: 0,
              pending: 0,
              locked: 0,
              estimated: 0
            }
          })

          data.user.updateProfile({
            displayName: this.form.name
          })

          console.log(data.user)

          data.user.sendEmailVerification()
        })
        .then(() => {
          localStorage.signUpEmail = this.form.email
          this.$router.push('/verify-email')
        })
        .catch((err) => {
          this.error = err.code
        })
    }
  }
}
</script>
