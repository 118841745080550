<template>
  <div>
    <form action="post" @submit.prevent="submitFormStep1">
      <div class="row g-3">
        <div class="col-12">
          <h3 class="form-title">Swap Bitcoin</h3>
          <label class="form-label">You give</label>
          <div class="input-group">
            <div class="curr-icon curr-icon-btc" v-if="giveCur == 'BTC'">
              <i class="icofont-bitcoin"></i>
            </div>
            <div class="curr-icon curr-icon-fiat" v-if="giveCur == 'USDT'">
              <i class="cc USDT" style="color: #fff"></i>
            </div>
            <select class="form-control" name="method" v-model="giveCur">
              <option value="USDT">USDT</option>
              <option value="BTC">BTC</option>
            </select>
            <input
              type="number"
              :step="giveCur == 'USDT' ? 0.01 : 0.00000001"
              :min="giveCur == 'USDT' ? 10 : 0.000001"
              :max="giveCur == 'USDT' ? usdt_balance : btc_balance"
              name="give_amount"
              class="form-control"
              placeholder="Fill amount"
              v-model="give_amount"
              required
            />
          </div>
        </div>

        <div class="col-12">
          <label class="form-label">You get</label>
          <div class="input-group">
            <div class="curr-icon curr-icon-btc" v-if="getCur == 'BTC'">
              <i class="icofont-bitcoin"></i>
            </div>
            <div class="curr-icon curr-icon-fiat" v-if="getCur == 'USDT'">
              <i class="cc USDT" style="color: #fff"></i>
            </div>
            <select class="form-control" name="method" disabled v-model="getCur">
              <option value="BTC">BTC</option>
              <option value="USDT">USDT</option>
            </select>
            <input type="number" step="0.00000001" name="get_amount" class="form-control" placeholder="Fill amount" v-model="get_amount" required />
          </div>
        </div>

        <div class="form-info" style="margin: 30px 0 10px">
          <div class="flex-between">
            <span class="item-left">BTC Price:</span>
            <span class="item-right big">{{ price }} $</span>
          </div>

          <div class="flex-between">
            <span class="item-left">You will get:</span>
            <span class="item-right big">{{ giveCur == 'USDT' ? get_amount.toFixed(8) : get_amount.toFixed(2) }} {{ getCur }}</span>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Swap Bitcoin</button>

        <div class="text-center">Time to fix price - 00:0{{ countDown }}</div>
      </div>
    </form>

    <b-modal v-model="modalSwap" hide-footer>
      <template #modal-header="{ close }">
        <h5 class="modal-title"></h5>
        <b-button class="btn-close" data-dismiss="modal" aria-label="Close" @click="close()"> </b-button>
      </template>
      <template>
        <div>
          <form @submit.prevent="" class="identity-upload">
            <div class="identity-content overlay" v-if="showFormOverlay">
              <i class="icofont-spinner anim-rotate" v-if="isFormLoading"></i>
              <div v-if="!isFormLoading">
                <span class="icon"><i class="icofont-check"></i></span>
                <h4>
                  <span>Your Swap was successfully done!</span>
                </h4>
                <p>Check your new balances</p>
                <button @click="closeSwapModal()" class="btn btn-primary">OKAY</button>
              </div>
            </div>
            <div class="identity-content">
              <h3 style="margin-top: 20px">
                <span>Confirm Swap</span>
              </h3>

              <div class="swap-icons">
                <div class="curr-icon curr-icon-btc" v-if="giveCur == 'BTC'">
                  <i class="icofont-bitcoin"></i>
                </div>
                <div class="curr-icon curr-icon-fiat" v-if="giveCur == 'USDT'">
                  <i class="cc USDT" style="color: #fff"></i>
                </div>
                <i class="icofont-long-arrow-right"></i>
                <div class="curr-icon curr-icon-btc" v-if="getCur == 'BTC'">
                  <i class="icofont-bitcoin"></i>
                </div>
                <div class="curr-icon curr-icon-fiat" v-if="getCur == 'USDT'">
                  <i class="cc USDT" style="color: #fff"></i>
                </div>
              </div>

              <div class="swap-info" style="margin: 30px 0 10px">
                <div class="flex-between">
                  <span class="item-left">You will give: </span>
                  <span class="item-right big">{{ giveCur == 'USDT' ? give_amount.toFixed(2) : give_amount.toFixed(8) }} {{ giveCur }}</span>
                </div>

                <div class="flex-between">
                  <span class="item-left">You will get: </span>
                  <span class="item-right big">{{ giveCur == 'USDT' ? get_amount.toFixed(8) : get_amount.toFixed(2) }} {{ getCur }}</span>
                </div>
              </div>
            </div>
          </form>
          <div class="modal-body">
            <!-- <form @submit.prevent="onBTCSendSubmit"> -->
            <button @click="onSwapConfirm()" class="btn btn-primary">Confirm Swap</button><br /><br />
            <button @click="closeSwapModal()" class="btn btn-primary btn-danger">Cancel</button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

export default {
  name: 'BuyFormStep1',
  data: function () {
    return {
      userID: '',
      price: 17000,
      total: 0,
      countDown: 9,

      swap_direction: 0,
      btc_balance: 0,
      usdt_balance: 0,
      give_amount: 0,
      get_amount: 0,

      giveCur: 'USDT',
      getCur: 'BTC',

      modalSwap: false,
      showFormOverlay: false,
      isFormLoading: false,

      balances: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0
      },
      balances_usdt: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0
      }
    }
  },
  created() {
    this.getBtcPrice()
    if (firebase.auth().currentUser.uid) {
      //console.log(firebase.auth().currentUser.uid)
      this.userID = firebase.auth().currentUser.uid
      db.collection('users')
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((doc) => {
          // this.getBtcPrice()
          this.btc_balance = doc.data().balances.available
          this.usdt_balance = doc.data().balances_usdt.available

          if (doc.data().balances) {
            this.balances.available = doc.data().balances.available
            this.balances.estimated = doc.data().balances.estimated
            this.balances.pending = doc.data().balances.pending
            this.balances.locked = doc.data().balances.locked
          }

          if (doc.data().balances_usdt) {
            this.balances_usdt.available = doc.data().balances_usdt.available
            this.balances_usdt.estimated = doc.data().balances_usdt.estimated
            this.balances_usdt.pending = doc.data().balances_usdt.pending
            this.balances_usdt.locked = doc.data().balances_usdt.locked
          }

          this.give_amount = this.usdt_balance
          this.get_amount = this.usdt_balance / this.price
        })
    }
  },
  mounted: function () {
    this.countDownTimer()
    this.getBtcPrice()

    setInterval(() => {
      this.getBtcPrice()
    }, this.countDown * 1000)
  },
  watch: {
    currency: function () {
      this.getBtcPrice()
      localStorage.currency = this.currency
    },
    giveCur: function () {
      if (this.giveCur == 'BTC') {
        this.getCur = 'USDT'
        this.give_amount = this.btc_balance
        this.get_amount = this.btc_balance * this.price
      } else if (this.giveCur == 'USDT') {
        this.getCur = 'BTC'
        this.give_amount = this.usdt_balance
        this.get_amount = this.usdt_balance / this.price
      }
    },
    price: function () {
      this.updateGetAmount()
    },
    give_amount: function () {
      this.updateGetAmount()
    },
    get_amount: function () {
      this.updateGiveAmount()
    },
    discount_code: function () {
      this.updateDiscountState()
      this.updateDiscountPrice()
      this.updateGetAmount()
    },
    countDown: function () {
      if (this.countDown == 0) {
        this.countDown = 9
        this.countDownTimer()
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    openSwapModal: function () {
      this.modalSwap = true
      this.showFormOverlay = false
      this.isFormLoading = false
    },
    closeSwapModal: function () {
      this.modalSwap = false
    },
    onSwapConfirm: function () {
      if (firebase.auth().currentUser.uid) {
        console.log(firebase.auth().currentUser.uid)

        const currBTCBalance = this.balances.available
        const currUSDTBalance = this.balances_usdt.available

        let newBTCBalance = this.giveCur == 'USDT' ? currBTCBalance + this.get_amount : currBTCBalance - this.give_amount
        let newUSDTBalance = this.giveCur == 'USDT' ? currUSDTBalance - this.give_amount : currUSDTBalance + this.get_amount

        db.collection('users')
          .doc(firebase.auth().currentUser.uid)
          .update({
            balances: {
              available: newBTCBalance,
              pending: this.balances.pending,
              estimated: this.balances.estimated,
              locked: this.balances.locked
            },
            balances_usdt: {
              available: newUSDTBalance,
              pending: this.balances_usdt.pending,
              estimated: this.balances_usdt.estimated,
              locked: this.balances_usdt.locked
            }
          })
          .then(() => {
            this.isFormLoading = true
            this.showFormOverlay = true
          })
          .then(() => {
            setTimeout(() => {
              this.isFormLoading = false
              console.log('Swap Successful!')
            }, '2000')
          })
          .catch((e) => {
            console.log(e.message)
          })
      }
    },
    submitFormStep1: function () {
      //   localStorage.discountCode = this.discount_code
      //   localStorage.fiatAmout = this.fiat_amount
      //   localStorage.discountAmout = this.discount_amount
      //   localStorage.isDiscountEnabled = this.isDiscountEnabled
      //   localStorage.currency = this.currency

      if (!this.user.loggedIn) {
        this.$router.push('/signin')
      } else {
        // this.$emit('form-step', '2')
        this.openSwapModal()
      }
    },
    countDownTimer: function () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    getBtcPrice: function () {
      fetch(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
        .then((response) => response.json())
        .then((data) => (this.price = parseFloat(data.price)))
    },
    updateGiveAmount: function () {
      let amount = 0
      amount = this.giveCur == 'USDT' ? this.get_amount * this.price : this.get_amount / this.price
      const newAmount = Math.round(amount * 1e2) / 1e2
      return (this.give_amount = newAmount)
    },
    updateGetAmount: function () {
      let amount = 0
      amount = this.giveCur == 'USDT' ? this.give_amount / this.price : this.give_amount * this.price
      const newAmount = Math.round(amount * 1e8) / 1e8
      return (this.get_amount = newAmount)
    }
  }
}
</script>
<style>
.swap-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
.swap-icons > div {
  margin: 0 15px;
}
.swap-icons .icofont-long-arrow-right {
  font-size: 44px;
  color: #bbb;
}
.swap-info {
  font-size: 18px;
}
.identity-content.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}
</style>