<template>
  <div id="main-wrapper" class="show">
    <div class="authincation section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-5 col-md-6">
            <auth-header />
            <div class="auth-form card">
              <div id="preloader" class="small" v-show="isLoading"><i>.</i><i>.</i><i>.</i></div>
              <div class="card-body">
                <h3 class="card-title text-center mt-3">Sign In</h3>
                <p class="text-center text-error">{{ error }}</p>

                <form method="post" name="myform" class="signin_validate row g-3" action="otp-2" @submit.prevent="onSubmit">
                  <div class="col-12">
                    <input type="email" class="form-control" placeholder="hello@example.com" name="email" v-model="form.email" required />
                  </div>
                  <div class="col-12">
                    <input type="password" class="form-control" placeholder="Password" name="password" v-model="form.password" required />
                  </div>
                  <div class="col-6">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                      <label class="form-check-label" for="flexSwitchCheckDefault">Remember me</label>
                    </div>
                  </div>
                  <div class="col-6 text-end">
                    <router-link to="reset">Forgot Password?</router-link>
                  </div>
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary btn-block">Sign in</button>
                  </div>
                  <!-- <div class="text-center">
                    <button type="submit" class="btn btn-outline-primary btn-block">Sign in with Google</button>
                  </div> -->
                </form>
              </div>
            </div>

            <p class="mt-3 mb-0 text-center">
              Don't have an account?
              <router-link class="text-primary" to="signup">Sign up</router-link>
            </p>

            <!-- <div class="privacy-link">
              <router-link to="signup"
                >Have an issue with 2-factor authentication?</router-link
              >
              <br />
              <router-link to="signup">Privacy Policy</router-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthHeader from '../../components/qCash/AuthHeader.vue'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import store from '../../store'

export default {
  components: { AuthHeader },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      error: null,
      isLoading: false
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {

          let user = data.user;

          if (user.emailVerified) {
            store.commit('SET_LOGGED_IN', true)
            this.$router.push('/wallet')
          } else {
            this.error = "Your account was not verificated by email. Please follow by link at your email to verificate"
            user.sendEmailVerification()
          }

          this.isLoading = false;
          
        })
        .catch((err) => {
          this.error = err.code;
          this.isLoading = false;
        })
    }
  }
}
</script>
