import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from 'vuexfire';

import firebase from "firebase/app";
import "firebase/auth";
import db from "./initFirebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      isEmailVerified: false,
      data: null,
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    ...vuexfireMutations,
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
      console.log(state.user.loggedIn);
    },
    SET_EMAIL_VERIFIED(state, value) {
      state.user.isEmailVerified = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    updateUser(state, payload) {
      state.user = payload;
    },
    SET_PROFILE_INFO(state, doc) {
      state.profileId = doc.id;
      state.profileEmail = doc.data().email;
      state.profileUsername = doc.data().name;
      console.log(state.profileId);
    },
    changeUsername(state, payload) {
      state.profileUsername = payload;
    },
  },
  actions: {
    bindUsers: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('users', db.collection('users'))
    }),
    async fetchUser({ commit }, user) {
      if (user && user.emailVerified) {
        commit("SET_LOGGED_IN", user !== null);
        commit("SET_EMAIL_VERIFIED", user.emailVerified);
      }
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
        });
      } else {
        commit("SET_USER", null);
      }
    },
    async getCurrentUser({ commit }) {
      const dataBase = db
        .collection("users")
        .doc(firebase.auth().currentUser.uid);
      const dbResults = await dataBase.get();
      commit("SET_PROFILE_INFO", dbResults);
    },
    async updateUserSettings({ commit, state }) {
      const dataBase = db
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((doc) => {
          let source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          console.log(source, " data: ", doc.data());
        });

      await dataBase.update({
        firstName: state.profileFirstName,
        username: state.profileUsername,
      });
      commit("setProfileInitials");
    },
    // async getCurrentUser({ commit }, user) {
    //   const dataBase = await db.collection("users").doc(firebase.auth().currentUser.uid);
    //   const dbResults = await dataBase.get();
    //   commit("setProfileInfo", dbResults);
    //   commit("setProfileInitials");
    //   const token = await user.getIdTokenResult();
    // },
  },
});
