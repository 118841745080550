<template>
  <layout>
    <div class="content-body">
      <div class="container">
        <div class="row">

          <div class="col-xxl-4 col-xl-6" v-if="this.verification != 'Verified'">
            <div class="card welcome-profile">
              <div class="card-body">
                <img src="../../assets/images/profile/2.png" alt="" />
                <h4>Welcome, {{ username }}!</h4>
                <p>Looks like you are not verified yet. Verify yourself to use the full potential of BuyBit.</p>

                <ul>
                  <li>
                    <router-link to="#">
                      <!-- <span class="verified"><i class="icofont-check-alt"></i></span> -->
                      <span class="not-verified"><i class="icofont-close-line"></i></span>
                      Verify account
                    </router-link>
                  </li>
                  <!-- <li>
                    <router-link to="#">
                      <span class="not-verified"><i class="icofont-close-line"></i></span>
                      Two-factor authentication (2FA)
                    </router-link>
                  </li> -->
                </ul>
                
              </div>
            </div>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-6" style="display: none">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Download App</h4>
              </div>
              <div class="card-body">
                <div class="app-link">
                  <h5>Get Verified On Our Mobile App</h5>
                  <p>Verifying your identity on our mobile app more secure, faster, and reliable.</p>
                  <router-link to="#" class="btn btn-primary">
                    <img src="../../assets/images/android.svg" alt="" />
                  </router-link>
                  <br />
                  <div class="mt-3"></div>
                  <router-link to="#" class="btn btn-primary">
                    <img src="../../assets/images/apple.svg" alt="" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-8 col-xl-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Information</h4>
                <router-link to="/settings-profile" class="btn btn-primary">Edit</router-link>
              </div>
              <div class="card-body">
                <form class="row">
                  <div class="col-lg-5 col-md-6">
                    <div class="user-info">
                      <span>EMAIL ADDRESS</span>
                      <h4>{{ email }}</h4>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="user-info">
                      <span>VERIFICATION STATUS</span>
                      <h4>
                        <span v-bind:class="{ 'text-error': verification == 'No Verified', 'text-warning': verification == 'Pending', 'text-success': verification == 'Verified'}">{{ verification }}</span>
                        </h4>
                    </div>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div class="user-info">
                      <span>JOINED SINCE</span>
                      <h4>{{regDate}}</h4>
                    </div>
                  </div>                  
                  <!-- <div class="col-md-8">
                    <div class="user-info">
                      <span>WALLET</span>
                      <h4>{{ wallet }}</h4>
                    </div>
                  </div> -->
                  <div class="col-md-4">
                    <div class="user-info">
                      <span>TYPE</span>
                      <h4>Personal</h4>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">VERIFY & UPGRADE</h4>
              </div>
              <div class="card-body">
                <h5>
                  Account Status :
                  <span v-bind:class="{ 'text-error': verification == 'No Verified', 'text-warning': verification == 'Pending', 'text-success': verification == 'Verified'}">
                    {{ verification }}
                    <i v-show="verification == 'No Verified'" class="icofont-warning"></i>
                  </span>
                </h5>
                <p>Your account is {{ verification == 'Verified' ? "verified" : "unverified" }}. Verification needed to enable funding, trading and withdrawal.</p>
                <router-link v-show="verification == 'No Verified'" to="/settings-profile" class="btn btn-primary">Get Verified</router-link>
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="card">
              <div class="card-body">
                <div class="invite-content">
                  <h4>Invite a friend and get $30</h4>
                  <p>You will receive up to $30 when they： <br />1.Buy Crypto <br />2.Deposit Crypto <br />3. Finish Trading Tasks</p>
                  <div class="copy-link">
                    <form action="#">
                      <div class="input-group">
                        <input type="text" class="form-control" v-bind:value="message" />
                        <span class="input-group-text c-pointer" @click="doCopy">Copy</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../../components/qCash/Layout.vue'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

export default {
  components: { Layout },
  data() {
    return {
      message: 'https://www.buybit.one/signup?ref_id=29387bx92837e92dnasdh32dx',
      username: '',
      email: '',
      wallet: "",
      regDate: "",
      verification: ""
    }
  },
  methods: {
    doCopy: function () {
      this.$copyText(this.message).then(
        function () {
          console.log('Copied');
        },
        function () {
          alert('Can`t copy')
        }
      )
    }
  },
  created() {
    db.collection('users')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot((doc) => {
        this.username = doc.data().name
        this.email = doc.data().email

        this.verification = doc.data().verification
        this.wallet = doc.data().wallet
        this.regDate = doc.data().regDate.toDate().toLocaleDateString("en-UK")
      })
  }
}
</script>
