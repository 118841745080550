<template>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <div class="header-content">
            <div class="header-left">
              <div class="brand-logo-big">
                <router-link to="/wallet">
                  <img width="150" src="../../assets/images/svg/buybit-logo-b-h.svg" alt="BuyBit" />
                </router-link>
              </div>
              <!-- <div class="search">
                <form action="#" v-on:submit.prevent="">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Here"
                    />
                    <span class="input-group-text"
                      ><i class="icofont-search"></i
                    ></span>
                  </div>
                </form>
              </div> -->
            </div>

            <div class="header-right">
              <div class="dark-light-toggle" @click="themeToggle()">
                <span class="dark"><i class="icofont-moon"></i></span>
                <span class="light"><i class="icofont-sun-alt"></i></span>
              </div>
              <div class="notification dropdown" @click="activeDropDown('notification')" :class="active_ === 'notification' && 'show'">
                <div class="notify-bell" data-toggle="dropdown">
                  <span><i class="icofont-alarm"></i></span>
                </div>
                <div class="dropdown-menu dropdown-menu-right notification-list mt-3" :class="active_ === 'notification' && 'show'">
                  <h4>Messages</h4>
                  <div class="lists">
                    <router-link to="#" class="">
                      <div class="d-flex align-items-center">
                        <span class="me-3 icon success"><i class="icofont-check"></i></span>
                        <div>
                          <p>Account created successfully</p>
                        </div>
                      </div>
                    </router-link>
                    <router-link to="#" v-show="verification != 'Verified'">
                      <div class="d-flex align-items-center">
                        <span class="me-3 icon pending"><i class="icofont-warning"></i></span>
                        <div>
                          <p>Verification is needed</p>
                        </div>
                      </div>
                    </router-link>

                    <!-- <router-link to="./settings-activity">More <i class="icofont-simple-right"></i></router-link> -->
                  </div>
                </div>
              </div>

              <div class="profile_log dropdown" @click="activeDropDown('profile')" :class="active_ && 'show'">
                <div class="user" data-toggle="dropdown">
                  <span class="thumb"><img src="../../assets/images/profile/2.png" alt="" /></span>
                  <span class="arrow"><i class="icofont-angle-down"></i></span>
                </div>
                <div class="dropdown-menu dropdown-menu-right mt-3" :class="active_ === 'profile' && 'show'">
                  <div class="user-email">
                    <div class="user">
                      <span class="thumb"><img src="../../assets/images/profile/2.png" alt="" /></span>
                      <div class="user-info">
                        <h5>{{ username }}</h5>
                        <span>{{ ellipseTextCenter(wallet) }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="user-id-block">
                      <div class="user-id">
                        <h5>User ID:</h5>
                        <span>{{ userID }}</span>
                      </div>
                  </div> -->

                  <div class="user-balance">
                    <div class="available">
                      <p>Balance</p>
                      <span>{{ totalCryptoBalance.toFixed(8) }} {{ balance.crypto_sign }}</span
                      ><br />
                      <span>{{ balances_usdt.available.toFixed(2) }} {{ balance.fiat_sign }}</span>
                    </div>
                    <div class="total">
                      <p>Total</p>
                      <span>{{ (totalFiatBalance + balances_usdt.available).toFixed(2) }} $</span>
                    </div>
                  </div>
                  <router-link to="profile" class="dropdown-item"> <i class="icofont-ui-user"></i>Profile </router-link>
                  <router-link to="wallet" class="dropdown-item"> <i class="icofont-wallet"></i>Wallet </router-link>
                  <router-link to="settings-profile" class="dropdown-item"> <i class="icofont-ui-settings"></i> Settings </router-link>
                  <!-- <router-link to="settings-activity" class="dropdown-item"> <i class="icofont-history"></i> Activity </router-link> -->
                  <!-- <router-link to="lock" class="dropdown-item"> <i class="icofont-lock"></i>Lock </router-link> -->
                  <router-link to="#" class="dropdown-item logout" @click.native="signOut"> <i class="icofont-logout"></i> Logout </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

import store from '../../store'

export default {
  name: 'Header',
  data() {
    return {
      username: '',
      email: '',
      verification: '',
      wallet: '',
      userID: '',
      balance: {
        crypto: 0,
        fiat: 0,
        crypto_sign: 'BTC',
        fiat_sign: 'USDT'
      },
      balances: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0
      },
      balances_usdt: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0
      },
      crypto_price: 0,
      active_: '',
      themes: ''
    }
  },
  watch: {
    crypto_price: function () {
      this.updateBalanceTotal()
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.getBtcPrice()
    }, 1000)

    // setInterval(() => {
    //   this.getBtcPrice()
    // }, 10000)
  },
  computed: {
    totalCryptoBalance: function () {
      return this.balances.available + this.balances.pending + this.balances.locked + this.balances.estimated
    },
    totalFiatBalance: function () {
      return this.totalCryptoBalance * this.crypto_price
    }
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/')
          store.commit('SET_LOGGED_IN', false)
        })
        .catch((error) => {
          console.log(error.message)
          this.$router.push('/')
        })
    },
    activeDropDown(value) {
      this.active_ = this.active_ === value ? '' : value
    },
    themeToggle(value) {
      let element = document.body
      let value_ = value && value.isOk
      this.themes = this.themes !== '' ? '' : 'dark-theme'
      element.classList = value_ ? value.theme : this.themes
      localStorage.setItem('theme', value_ ? value.theme : this.themes)
    },
    getBtcPrice: function () {
      fetch(`https://api.binance.com/api/v3/ticker/price?symbol=BTC${this.balance.fiat_sign ? this.balance.fiat_sign : 'USDT'}`)
        .then((response) => response.json())
        .then((data) => {
          this.crypto_price = parseFloat(data.price)
        })
        .then(() => {
          this.updateBalanceTotal()
        })
    },
    updateBalanceTotal() {
      this.balance.fiat = this.totalCryptoBalance * this.crypto_price
    },
    ellipseTextCenter(str) {
      if (str.length > 35) {
        return str.substr(0, 10) + '...' + str.substr(str.length - 10, str.length)
      }
      return str
    }
  },
  created: function () {
    let theme = localStorage.getItem('theme')
    this.themeToggle({ theme, isOk: true })
    this.themes = theme

    if (firebase.auth().currentUser) {
      this.userID = firebase.auth().currentUser.uid
      db.collection('users')
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((doc) => {
          //console.log('Current data: ', doc.data())

          this.username = doc.data().name
          this.email = doc.data().email
          this.verification = doc.data().verification
          this.wallet = doc.data().wallet

          if (doc.data().balances) {
            this.balances.available = doc.data().balances.available
            this.balances.estimated = doc.data().balances.estimated
            this.balances.pending = doc.data().balances.pending
            this.balances.locked = doc.data().balances.locked
          }

          if (doc.data().balances_usdt) {
            this.balances_usdt.available = doc.data().balances_usdt.available
            this.balances_usdt.estimated = doc.data().balances_usdt.estimated
            this.balances_usdt.pending = doc.data().balances_usdt.pending
            this.balances_usdt.locked = doc.data().balances_usdt.locked
          }
        })
    }
  }
}
</script>
