import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import store from './store';


const firebaseConfig = {
    apiKey: "AIzaSyDDUhtSIhtt4q4eYeR6reso3u1HMOtNFg4",
    authDomain: "buybit-one.firebaseapp.com",
    projectId: "buybit-one",
    storageBucket: "buybit-one.appspot.com",
    messagingSenderId: "610847460763",
    appId: "1:610847460763:web:faf713f44c7603fb0e653e",
    measurementId: "G-QW848EHE4M"
  };

// OLD CONFIG
// const firebaseConfig = {
//     apiKey: "AIzaSyDPJFZ1_acDbijWj9K9bCzoiroCHvd6lSA",
//     authDomain: "buybit-web.firebaseapp.com",
//     projectId: "buybit-web",
//     storageBucket: "buybit-web.appspot.com",
//     messagingSenderId: "501576276031",
//     appId: "1:501576276031:web:c6071d9aac94c6700a808a",
// };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();

/**
 * Sync store.state.user with firebase.auth().currentUser
 *
 * This callback is called when firebase.auth() detects user changes,
 * so just update the vuex store with the new user object.
 */
firebase.auth().onAuthStateChanged(user => {
    store.dispatch("fetchUser", user);
});