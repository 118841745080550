<template>
  <div style="height: 330px">
    <apexchart
      type="area"
      height="300"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import apexchart from "vue-apexcharts";
export default {
  name: "AnalyticsChart",
  components: { apexchart },
  data() {
    return {
      series: [
        {
          name: "Desktops",
          data: [110, 41, 120, 51, 49, 160, 69, 91, 148],
        },
      ],
      chartOptions: {
        animations: {
          enabled: false,
        },

        height: "300",
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
        },

        colors: ["#2F2CD8"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
          borderColor: "#F1F1F1",
        },
        xaxis: {
          categories: [
            "Jan 01",
            "Jan 02",
            "Jan 03",
            "Jan 04",
            "Jan 05",
            "Jan 06",
            "Jan 07",
            "Jan 08",
            "Jan 09",
          ],
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#3E4954",
            },
            formatter: function (y) {
              return y.toFixed(0) + "";
            },
          },
        },
      },
    };
  },
};
</script>
