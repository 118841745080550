<template>
  <layout :active="4">
    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6" v-for="(priceData, index) in priceDatas" :key="index">
            <div class="price-widget" :class="`bg-${priceData.bg}`">
              <!-- <router-link to="price-details"> -->
                <div class="price-content">
                  <div class="icon-title">
                    <i class="cc" :class="`${priceData.bg.toUpperCase()}-alt`"></i>
                    <span>{{ priceData.title }}</span>
                  </div>
                  <h5>{{priceData.price && parseFloat(priceData.price).toFixed(2)}} $</h5>
                </div>
                <currency-chart />
              <!-- </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </layout>
</template>

<script>
import CurrencyChart from '../../components/qCash/CurrencyChart.vue'
import Layout from '../../components/qCash/Layout.vue'
export default {
  components: { Layout, CurrencyChart },
  data() {
    return {
      priceDatas: [
        { bg: 'btc', title: 'Bitcoin', pair: 'BTCUSDT' },
        { bg: 'eth', title: 'Ethereum', pair: 'ETHUSDT' },
        { bg: 'xrp', title: 'Ripple', pair: 'XRPUSDT' },
        { bg: 'ltc', title: 'Litecoin', pair: 'LTCUSDT' },
        { bg: 'ada', title: 'Cardano', pair: 'ADAUSDT' },
        { bg: 'eos', title: 'EOS', pair: 'EOSUSDT' },
        { bg: 'xmr', title: 'Monero', pair: 'XMRUSDT' },
        { bg: 'dash', title: 'Dash', pair: 'DASHUSDT' },
        { bg: 'etc', title: 'Ethereum Classic', pair: 'ETCUSDT' }
      ],
      cryptoPriceData: [],
      filteredPairs: []
    }
  },
  mounted: function () {
    this.getCryptoPairsPrice()
  },
  watch: {
    filteredPairs: function () {
      this.mergeArrays();
    },
  },
  methods: {
    getCryptoPairsPrice: function () {
      fetch(`https://api.binance.com/api/v3/ticker/price`)
        .then((response) => response.json())
        .then((data) => {
          this.cryptoPriceData = data
          return data
        })
        .then((data) => {
          this.setCryptoPairsPrice(data)
        })
        .then(() => {
          this.mergeArrays()
        })
    },
    setCryptoPairsPrice: function (data) {
      let pairs = []
      this.priceDatas.forEach(function (coin) {
        //console.log(coin.pair)
        // let pair = data.filter((pair) => pair.symbol == coin.pair)
        const pair = data.find((pair) => pair.symbol == coin.pair)
        pairs.push(pair)
      })
      this.filteredPairs = pairs
    },
    mergeArrays: function () {
      // console.log(this.priceDatas)
      // console.log(this.filteredPairs)

      let filteredPairs = this.filteredPairs
      //console.log(filteredPairs[0].price)

      let datas = this.priceDatas.map((x, i) => {
        //console.log(filteredPairs[i].price)
        x.price = filteredPairs[i].price
        return x
      })

      this.priceDatas = datas;
      //console.log(datas)
    }
  }
}
</script>
