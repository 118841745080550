import Vue from "vue";
import VueRouter from "vue-router";
import Reset from "./views/auth/Reset";
import ResetConfirm from "./views/auth/ResetConfirm";
import Signin from "./views/auth/Signin";
import Signup from "./views/auth/Signup";
import VerifyEmail from "./views/auth/VerifyEmail";
// import Locked from "./views/auth/Locked";
// import VerifyCode from "./views/auth/VerifyCode";
// import VerifyPhone from "./views/auth/VerifyPhone";

import Index from "./views/Intro";

// DashBoard
import Dashboard from "./views/dashboard/Index";
import Price from "./views/dashboard/Price";
import PriceDetails from "./views/dashboard/PriceDetails";
import Profile from "./views/dashboard/Profile";
import Activity from "./views/dashboard/settings/Activity";
import API from "./views/dashboard/settings/API";
import Application from "./views/dashboard/settings/Application";
import Fees from "./views/dashboard/settings/Fees";
import Payment from "./views/dashboard/settings/Payment";
import Privacy from "./views/dashboard/settings/Privacy";
// setting
import SettingProfile from "./views/dashboard/settings/ProfileEdit";
import Security from "./views/dashboard/settings/Security";
import Trade from "./views/dashboard/Trade";
import Wallet from "./views/dashboard/Wallet";
import Test from "./views/dashboard/Test";

//Firebase
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Index,
    meta: {
      title: "Home",
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/price",
    name: "Price",
    component: Price,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/price-details",
    name: "PriceDetails",
    component: PriceDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trade",
    name: "Trade",
    component: Trade,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: Wallet,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/settings-application",
    name: "Application",
    component: Application,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings-security",
    name: "Security",
    component: Security,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings-profile",
    name: "SettingProfile",
    component: SettingProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings-activity",
    name: "Activity",
    component: Activity,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings-privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings-payment-method",
    name: "Payment",
    component: Payment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings-api",
    name: "API",
    component: API,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings-fees",
    name: "Fees",
    component: Fees,
    meta: {
      requiresAuth:true,
    },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-confirm",
    name: "ResetConfirm",
    component: ResetConfirm,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: "/otp-1",
  //   name: "VerifyPhone",
  //   component: VerifyPhone,
  // },
  // {
  //   path: "/otp-2",
  //   name: "VerifyCode",
  //   component: VerifyCode,
  // },
  // {
  //   path: "/lock",
  //   name: "Locked",
  //   component: Locked,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let user = firebase.auth().currentUser;

  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user && user.emailVerified) {
      //console.log("VERIFIED EMAIL: " + user.emailVerified);

      return next();
    }
    return next({ name: "Signin" });
  }
  return next();
});

export default router;
