<template>
    <div>
      <BuyFormStep1 @form-step="updateFormStep" v-if="formStep == 1" />
      <BuyFormStep2 @form-step="updateFormStep" v-if="formStep == 2" />
      <BuyFormStep3 @form-step="updateFormStep" v-if="formStep == 3" />
      <BuyFormStep4 @form-step="updateFormStep" v-if="formStep == 4" />
    </div>
</template>

<script>
import BuyFormStep1 from "../BuyForm/BuyFormStep1.vue";
import BuyFormStep2 from "../BuyForm/BuyFormStep2.vue";
import BuyFormStep3 from "../BuyForm/BuyFormStep3.vue";
import BuyFormStep4 from "../BuyForm/BuyFormStep4.vue";

export default {
  name: "BuyForm",
  components: {
    BuyFormStep1,
    BuyFormStep2,
    BuyFormStep3,
    BuyFormStep4,
  },
  data: function () {
    return {
      formStep: 1,
    };
  },
  methods: {
    updateFormStep: function(step) {
      this.formStep = step;
    },
  },
};
</script>