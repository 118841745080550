<template>
  <div id="main-wrapper" class="show">
    <div class="authincation section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-5 col-md-6">
            <auth-header :title="'Sign in to BuyBit'" />
            <div class="auth-form card">
              <div class="card-body">
                <div class="identity-content" v-if="!isEmailVerified && !emailVerificationError">
                  <span class="icon"><i class="icofont-email"></i></span>
                  <p>
                    We`ve sent verification email to the <strong class="text-dark">{{ signUpEmail }}</strong
                    >. Click the link inside to get started!
                  </p>
                </div>

                <div class="identity-content" v-if="isEmailVerified">
                  <span class="icon"><i class="icofont-flag-alt-2"></i></span>
                  <p class="big">Your email was successfully verified! <br />Now you can login to dashboard</p>
                  <router-link class="btn btn-primary btn-block" to="/wallet">Go to Login</router-link>
                </div>

                <div class="identity-content" v-if="emailVerificationError">
                  <span class="icon icon-error"><i class="icofont-error"></i></span>
                  <p class="big">Your email was not verified!</p>

                <form method="post" @submit.prevent="resendVerifLink" class="signin_validate row g-3">
                  <div class="col-12">
                    <input type="text" class="form-control" placeholder="Your email" name="name" v-model="signUpEmail" required />
                  </div>
                  <button type="submit" class="btn btn-primary btn-block">Resend email</button>
                </form>

                </div>

                <!-- <div class="text-center">
                  <router-link to="signup">Email didn't arrive?</router-link>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthHeader from '../../components/qCash/AuthHeader.vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  components: { AuthHeader },
  data: function () {
    return {
      isEmailVerified: false,
      isUserLoggedIn: false,
      emailVerificationError: false,
      emailVerificationMessage: null,
      actionCode: null,
      signUpEmail: null
    }
  },
  mounted: function () {

    this.isEmailVerified = this.checkEmailVerification();

    this.signUpEmail = localStorage.getItem("signUpEmail");
    this.actionCode = this.getParameterByName('oobCode');

    if (this.actionCode) {
      this.applyActionCode()
    }

  },
  methods: {
    checkEmailVerification() {
      let user = firebase.auth().currentUser;

      if (user) {
        this.isUserLoggedIn = true;
      }

    },
    resendVerifLink() {
      let user = firebase.auth().currentUser;
      console.log(user)

      if (user) {
        user.sendEmailVerification()
        this.emailVerificationError = false
      }
      
    },
    applyActionCode() {
      firebase
        .auth()
        .applyActionCode(this.actionCode)
        .then(() => {
          this.isEmailVerified = true
        })
        .catch((err) => {
          console.log(err);
          this.emailVerificationError = true
          this.emailVerificationMessage = err.message

          console.log(this.emailVerificationError);
          console.log(this.emailVerificationMessage);
        })
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[[]]/g, '\\$&')
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, ' '))
    }
  }
}
</script>

<style scoped>
.auth-form {
  font-size: 15px;
}
</style>
