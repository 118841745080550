<template>
  <div class="row g-3">
    <div class="col-12">
      <div class="title-flex">
        <div class="back-btn" @click="$emit('form-step', '2')">
          <svg class="text-main" fill="currentColor" width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1.645L7.28745 0L0 7L7.28745 14L9 12.355L3.43725 7L9 1.645Z"></path>
          </svg>
        </div>
        <h3 class="form-title">Payment</h3>
      </div>
      <!-- <div class="subtitle-small">
        <p>Please check all requisites carefully before send.</p>
      </div> -->
    </div>

    <div class="form-info">
      <div class="flex-between">
        <span class="item-left">Amount:</span>
        <span class="item-right big">{{ fiat_amount }} {{ currency }}</span>
      </div>
      <div class="flex-between">
        <span class="item-left">BTC Price:</span>
        <span class="item-right big" v-bind:class="{ line: isDiscountEnabled }">{{ price }} {{ currency }}</span>
      </div>
      <div class="flex-between" v-if="isDiscountEnabled">
        <span class="item-left">Your discount:</span>
        <span class="item-right big"> {{ discount_percent }}% ({{ discount_amount }} {{ currency }}) </span>
      </div>
      <div class="flex-between" v-if="isDiscountEnabled">
        <span class="item-left">Price with discount:</span>
        <span class="item-right big">{{ discount_price }} {{ currency }}</span>
      </div>
      <div class="flex-between">
        <span class="item-left">You will get:</span>
        <span class="item-right big">{{ crypto_amount }} BTC</span>
      </div>
    </div>

    <hr />

    <div class="col-12">
      <div class="text-center">
        <h2 class="pay-amount">{{ fiat_amount }} {{ currency }}</h2>
        <label class="form-label">You need to send</label>
      </div>
    </div>

    <hr />

    <div class="col-12">
      <div class="title-flex">
        <h3 class="form-title">Requisites</h3>
      </div>
      <!-- <div class="subtitle-small">
        <p>Please check all requisites carefully before send.</p>
      </div> -->

      <div class="form-info requisites">
        <div>
          <label class="form-label">Choose Network</label>
          <div class="input-group">
            <div style="width: 100%">
              <select class="form-control" name="method" v-model="network">
                <option value="TRX">TRX - Tron (TRC20)</option>
                <option value="BEP20">BEP20 - Binance Smart Chain (BEP20)</option>
              </select>
              <div class="input-icon">
                <i class="icofont-caret-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <label class="form-label">Deposit Wallet</label>
          <div class="input-group">
            <div style="width: 100%">
              <input type="text" name="network" class="form-control" v-model="currentWallet" disabled />
              <div class="input-icon" v-clipboard:copy="currentWallet">
                <i class="icofont-ui-copy" style="font-size: 18px"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="flex-between">
          <span class="item-left">
            <p class="item-req-suptitle">Address</p>
            <p class="item-req-title">{{ wallet }} &nbsp; <i class="icofont-ui-copy" @click="doCopy"></i></p>
          </span>
          <span class="item-right">
            <p class="item-req-suptitle">Expected arrival</p>
            <p class="item-req-title">12 confirmations</p>
          </span>
        </div>
        <div class="flex-between">
          <span class="item-left">
            <p class="item-req-suptitle">Coin</p>
            <p class="item-req-title">USDT (Tether)</p>
          </span>
          <span class="item-right">
            <p class="item-req-suptitle">Network</p>
            <p class="item-req-title">Ethereum (ERC20)</p>
          </span>
        </div> -->
        <div class="flex">
          <span class="item-left">
            <br />
            <p class="item-req-suptitle">Send only USDT to this address</p>
            <p class="item-req-suptitle">
              Be sure that you send by this network: <span class="text-error">{{ network == 'BEP20' ? 'Binance Smart Chain (BEP20)' : 'Tron (TRC20)' }}</span>
            </p>
          </span>
        </div>

        <div style="margin-top: 30px">
          <button type="button" class="btn btn-primary" @click.prevent="onPaidSubmit" :disabled='isDisabled'>Submit order</button>
        </div>
      </div>

      <!-- <div class="form-info requisites">
        <label class="form-label">EUR requisites</label>
        <div class="flex-between">
          <span class="item-left">
            <p class="item-req-suptitle">Account holder</p>
            <p class="item-req-title">BuyBit Inc.</p>
          </span>
          <span class="item-right">
            <p class="item-req-suptitle">IBAN</p>
            <p class="item-req-title">BE61 9672 3573 4517</p>
          </span>
        </div>
        <label class="form-label">USD requisites</label>
        <div class="flex-between">
          <span class="item-left">
            <p class="item-req-suptitle">Routing number</p>
            <p class="item-req-title">084009519</p>
          </span>
          <span class="item-right">
            <p class="item-req-suptitle">Account number</p>
            <p class="item-req-title">9600001502429000</p>
          </span>
        </div>
      </div> -->

      <b-modal v-model="modalShowSuccess" id="Success" hide-footer>
        <template #modal-header="{ close }">
          <h5 class="modal-title"></h5>
          <b-button class="btn-close" data-dismiss="modal" aria-label="Close" @click="close()"> </b-button>
        </template>
        <template>
          <div>
            <form @submit.prevent="" class="identity-upload">
              <div class="identity-content">
                <span class="icon"><i class="icofont-check"></i></span>
                <h4>
                  <span>Your order was </span>
                  <span v-if="!isExpired">succesfully created</span>
                  <span class="text-error" v-if="isExpired">expired</span>
                </h4>
                <p>Please send {{ currency }} to the wallet below to get your BTC</p>

                <h3 class="text-center">
                  <Countdown :date="countdownEnd" @onFinish="isExpired = true"></Countdown>
                </h3>
              </div>
            </form>
            <div class="modal-body">
              <label class="form-label">Deposit Wallet</label>
              <div class="input-group">
                <div style="width: 100%">
                  <input type="text" name="network" class="form-control" v-model="modalData.paidToWallet" disabled />
                  <div class="input-icon" v-clipboard:copy="modalData.paidToWallet">
                    <i class="icofont-ui-copy" style="font-size: 18px"></i>
                  </div>
                </div>
              </div>

              <div class="form-info requisites" style="margin-top: 30px">
                <div class="flex-between">
                  <span class="item-left">
                    <p class="item-req-suptitle">Amount to send</p>
                    <p class="item-req-title">{{ modalData.fiatAmount }}</p>
                  </span>
                  <span class="item-right">
                    <p class="item-req-suptitle">You will get</p>
                    <p class="item-req-title">{{ modalData.cryptoAmount }}</p>
                  </span>
                </div>
                <div class="flex-between">
                  <span class="item-left">
                    <p class="item-req-suptitle">Coin</p>
                    <p class="item-req-title">USDT (Tether)</p>
                  </span>
                  <span class="item-right">
                    <p class="item-req-suptitle">Network</p>
                    <p class="item-req-title">{{modalData.network}}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal v-model="modalShowError" hide-footer>
        <template #modal-header="{ close }">
          <h5 class="modal-title"></h5>
          <b-button class="btn-close" data-dismiss="modal" aria-label="Close" @click="close()"> </b-button>
        </template>
        <template>
          <div class="auth-form">
            <form @submit.prevent="" class="identity-upload">
              <div class="identity-content">
                <span class="icon icon-error"><i class="icofont-error"></i></span>
                <h4>Something goes wrong</h4>
                <p>Please try again</p>
              </div>
            </form>
          </div>
        </template>
      </b-modal>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="onSubmit">I`ve se</button> -->
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

import Countdown from '../Countdown'

export default {
  name: 'BuyFormStep4',
  components: { Countdown },
  data: function () {
    return {
      discount_code: '',
      discount_percent: 9,
      isDiscountEnabled: false,
      discount_price: 35000,
      discount_amount: 0,
      price: 62167,
      currency: 'USDT',
      fiat_amount: 1000,
      crypto_amount: 0.00000000,
      countDown: 4,
      total: 1,
      pay_amount: 1000,

      network: 'TRX',
      BEP20_wallet: '0xe3f65A9C1B64956E5893bc56818Dcc09AE5D5d57',
      TRX_wallet: 'TSQ7F1cuMrWyYzgtBdHNyj4GqykSNeLNB6',
      currentWallet: 'TSQ7F1cuMrWyYzgtBdHNyj4GqykSNeLNB6',

      modalShowSuccess: false,
      modalShowError: false,

      countdownEnd: null,
      isExpired: false,

      error: false,
      isDisabled: false,

      modalData: {}
    }
  },
  mounted: function () {
    this.getBtcPrice()
    this.updateDiscountAmount()
    this.updateCryptoAmount()

    //console.log(this.countdownEnd)

    if (localStorage.currency) {
      this.currency = localStorage.currency
    }

    if (localStorage.isDiscountEnabled) {
      this.isDiscountEnabled = (localStorage.isDiscountEnabled === "true")
    }

    if (localStorage.fiatAmout) {
      this.fiat_amount = localStorage.fiatAmout
    }

    if (localStorage.discountAmout) {
      this.discount_amount = localStorage.discountAmout
    }

    // console.log(this.isDiscountEnabled)
    // console.log(this.currency)

    setInterval(() => {
      this.getBtcPrice()
    }, this.countDown * 1000)
  },
  watch: {
    network: function () {
      console.log(this.network)
      this.currentWallet = this.network == 'BEP20' ? this.BEP20_wallet : this.TRX_wallet
    },
    currency: function () {
      this.getBtcPrice()
    },
    price: function () {
      this.updateCryptoAmount()
      this.updateDiscountPrice()
    },
    isDiscountEnabled: function () {
      if (localStorage.isDiscountEnabled) {
        this.isDiscountEnabled = (localStorage.isDiscountEnabled === "true")
      }
    }
    // fiat_amount: function () {
    //   //this.updateCryptoAmount();
    //   this.updateDiscountAmount();
    // },
  },
  methods: {
    addMinutes: function (date, minutes) {
      return new Date(date.getTime() + minutes * 60000)
    },
    doCopy: function (copy) {
      this.$copyText(copy).then(
        function () {
          console.log('Copied' + copy)
        },
        function () {
          alert('Can`t copy')
        }
      )
    },
    saveModalData: function (data) {
      this.modalData = data
    },
    updateDiscountState: function () {
      if (localStorage.isDiscountEnabled) {
        this.isDiscountEnabled = (localStorage.isDiscountEnabled === "true")
      }
    },
    getBtcPrice: function () {
      fetch(`https://api.binance.com/api/v3/ticker/price?symbol=BTC${this.currency}`)
        .then((response) => response.json())
        .then((data) => (this.price = parseFloat(data.price)))
    },
    updateCryptoAmount: function () {
      let amount = 0

      if (this.isDiscountEnabled) {
        amount = this.fiat_amount / this.discount_price
      } else {
        amount = this.fiat_amount / this.price
      }

      const newAmount = Math.round(amount * 1e8) / 1e8
      return (this.crypto_amount = newAmount)
    },
    updateDiscountPrice: function () {
      let price = this.price - (this.price * this.discount_percent) / 100
      return (this.discount_price = Math.round(price * 1e2) / 1e2)
    },
    updateDiscountAmount: function () {
      if (this.isDiscountEnabled) {
        let amount = (this.fiat_amount * this.discount_percent) / 100
        return (this.discount_amount = Math.round(amount * 1e2) / 1e2)
      }
    },
    onPaidSubmit() {

      if (this.isDisabled == true && !this.error) {
        this.modalShowSuccess = true
      }

      this.isDisabled = true

      db.collection('users')
        .doc(firebase.auth().currentUser.uid)
        .collection('orders')
        .add({
          network: this.network == 'BEP20' ? 'Binance Smart Chain (BEP20)' : 'Tron (TRC20)',
          fiatAmount: this.fiat_amount + ' ' + this.currency,
          cryptoAmount: this.crypto_amount + 'BTC',
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          paidToWallet: this.currentWallet,
          price: this.price + this.currency,
          discountPrice: this.discount_price + this.currency
        })
        .then(() => {
          //console.log('data sent')

          let data = {
            network: this.network == 'BEP20' ? 'Binance Smart Chain (BEP20)' : 'Tron (TRC20)',
            fiatAmount: this.fiat_amount + ' ' + this.currency,
            cryptoAmount: this.crypto_amount + ' BTC',
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            paidToWallet: this.currentWallet,
            price: this.price + this.currency,
            discountPrice: this.discount_price + this.currency
          }

          this.saveModalData(data)

          //console.log(this.modalData)

          this.countdownEnd = this.addMinutes(new Date(), 30)
          this.modalShowSuccess = true
        })
        .catch(() => {
          this.error = true
          this.modalShowError = true
        })
    }
  }
}
</script>

<style scoped>
.form-control {
  font-size: 14px;
  padding: 0 15px;
  padding-right: 40px;
}
.input-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.input-icon:active {
  opacity: 0.7;
}
.form-control:disabled {
  background: transparent;
  cursor: text;
}
.intro-form-exchange .input-group select {
  padding-left: 15px;
}
.title-flex {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}
.title-flex .form-title {
  margin: 0;
}
.back-btn {
  display: inline-block;
  margin-right: 10px;
  padding: 5px;
  cursor: pointer;
}
.intro-form-exchange .wallet-address-input .form-control {
  font-size: 16px;
  font-weight: 500;
}
.wallet-address-input {
  margin-bottom: 15px;
}
.requisites .item-req-suptitle {
  margin: 0;
  font-size: 13px;
  word-break: break-word;
}
.requisites .item-req-title {
  margin: 0 0 20px;
  font-size: 15px;
  font-weight: 600;
  word-break: break-word;
}
.requisites label {
  font-weight: 500;
  text-transform: uppercase;
}
.requisites .flex-between {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.requisites .flex-between > * {
  flex: 1 1 50%;
}
@media (max-width: 992px) {
  .requisites .flex-between > * {
    flex: 1 1 100%;
  }
}
</style>