<template>
  <layout>
    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-xl-6">
            <div class="card">
              <div class="card-body">
                <div class="invite-content">
                  <h4>Get Link</h4>
                  <div class="copy-link">
                    <form action="#">
                      <div class="input-group">
                        <input type="text" class="form-control" v-bind:value="link" />
                        <span class="input-group-text c-pointer" @click="doCopy">Copy</span>
                      </div>
                    </form>
                  </div>

                  <ul>
                    <template v-for="item in datas">
                      <li v-bind:key="item.id">
                        {{ 'Total: ' + totalItem(item.out) + ' BTC' }}
                        <br />
                        <a v-bind:href="'https://www.blockchain.com/ru/btc/tx/' + item.hash" target="_blank">{{ item.hash }}</a>
                        <br /><br />
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../../components/qCash/Layout.vue'

export default {
  components: { Layout },
  data() {
    return {
      link: 'https://www.buybit.one/signup?ref_id=29387bx92837e92dnasdh32dx',
      datas: [],
      timer: null,
      wallet: ""
    }
  },
  methods: {
    getWallet() {
        fetch('https://blockchain.info/unconfirmed-transactions?format=json')
          .then((response) => response.json())
          .then((data) => data.txs.filter((tx) => tx.out[0].addr.startsWith("bc1")))
          .then((filtered) => (filtered.length > 1 ? this.wallet = filtered[0].out[0].addr : console.log('Not found wallet!')))
          .then((filtered) => console.log(filtered))
    },
    getData() {
      this.timer = setInterval(() => {
        fetch('https://blockchain.info/unconfirmed-transactions?format=json')
          .then((response) => response.json())
          .then((data) => data.txs.filter((tx) => tx.out.some((out) => out.value > 9100000000 && out.value < 10000000000)))
          //.then((data) => data.txs.filter((tx) => tx.out[0].value > 9990000000 && tx.out[0].value < 10000000000))
          .then((filtered) => (filtered.length > 1 ? (this.datas = filtered) : console.log('Not found transaction!')))
      }, 10000)
    },
    doCopy: function () {
      this.$copyText(this.message).then(
        function () {
          console.log('Copied')
        },
        function () {
          alert('Can`t copy')
        }
      )
    },
    totalItem: function (items) {
      console.log('Items array: ', items)
      let sum = 0
      items.forEach(function (item) {
        sum += parseFloat(item.value)
      })
      return sum / 100000000
    }
  },
  created() {
    this.getData()
    this.getWallet()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    datas(val) {
      console.log('Value: ', val)
      clearInterval(this.timer)
      console.log('Interval Cleared!')
      console.log(new Date())
    }
  }
}
</script>
