<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Recent Transactions</h4>
    </div>
    <div class="card-body">
      <div class="table-responsive transaction-table">
        <table class="table table-striped responsive-table">
          <thead>
            <tr>
              <th>Currency</th>
              <th>Date &amp; Time</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Hash</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="transaction in transactions" v-bind:key="transaction.id">
              <td class="coin-name">
                <i class="cc BTC"></i>
                Bitcoin
              </td>
              <td>{{ transaction.date.toDate().toLocaleDateString() }}</td>
              <td>
                <span v-bind:class="[transaction.type == 'in' ? 'success-arrow' : 'danger-arrow']">
                  <i v-bind:class="[transaction.type == 'in' ? 'icofont-arrow-down' : 'icofont-arrow-up']"></i>
                  {{ transaction.type == 'in' ? 'Receive' : 'Send' }}
                </span>
              </td>
              <td v-bind:class="[transaction.type == 'in' ? 'text-success' : 'text-danger']">{{ transaction.amount.toFixed(8) }} BTC</td>
              <td>
                <strong>{{ transaction.status }}</strong>
              </td>
              <td>
                <a v-bind:href="'https://blokchair.info/bitcoin/transaction/' + transaction.hash" target="_blank">{{ transaction.hash }}</a>
              </td>
            </tr>
          </tbody>
          <tbody style="display: none">
            <tr>
              <td>523640</td>
              <td class="coin-name">
                <i class="cc BTC"></i>
                Bitcoin
              </td>
              <td>January 15</td>
              <td>
                <span class="danger-arrow"><i class="icofont-arrow-up"></i> Send</span>
              </td>
              <td class="text-danger">-0.000242 BTC</td>
              <td>
                <strong>Awaiting confirmations (0/6)</strong>
              </td>
            </tr>
            <tr>
              <td>523640</td>
              <td class="coin-name">
                <i class="cc BTC"></i>
                Bitcoin
              </td>
              <td>January 15</td>
              <td>
                <span class="success-arrow"><i class="icofont-arrow-down"></i>Receive</span>
              </td>
              <td class="text-success">0.000242 BTC</td>
              <td>
                <strong>Confirmed</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../initFirebase'

export default {
  name: 'RecentTransactions',
  data: function () {
    return {
      transactions: {}
    }
  },
  created() {
    if (firebase.auth().currentUser.uid) {
      db.collection('users')
        .doc(firebase.auth().currentUser.uid)
        .collection('transactions')
        .orderBy('date')
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            console.log(change)

            let backend_transactions = snapshot.docs.map((doc) => doc.data()).reverse()
            let transactions_id = snapshot.docs.map((doc) => doc.id).reverse()
            this.transactions = backend_transactions

            if (change.type === 'added') {
              if (backend_transactions[0].new_transaction) {
                this.showToast('You received ' + backend_transactions[0].amount.toFixed(8) + ' BTC')

                db.collection('users').doc(firebase.auth().currentUser.uid).collection('transactions').doc(transactions_id[0]).update({
                  new_transaction: false
                })

                console.log(transactions_id[0])
                this.updateTransactionStatus(transactions_id[0], 10000, 7)
              }

              console.log(backend_transactions)
            }
          })
        })
    }
  },
  methods: {
    updateTransactionStatus: function (id, delay, repetitions) {
      var x = 1
      var intervalID = window.setInterval(function () {
        // callback()

        db.collection('users')
          .doc(firebase.auth().currentUser.uid)
          .collection('transactions')
          .doc(id)
          .update({
            status: `Awaiting confirmations (${x}/6)`
          })

        if (++x === repetitions) {
          window.clearInterval(intervalID)

          db.collection('users').doc(firebase.auth().currentUser.uid).collection('transactions').doc(id).update({
            status: `Confirmed`
          })
        }
      }, delay)
    },
    updateFormStep: function (step) {
      this.formStep = step
    },
    showToast: function (text) {
      this.$toast.success(text, {
        position: 'bottom-right',
        timeout: 5000,
        maxToasts: 1,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        showCloseButtonOnHover: true,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true
      })
    }
  }
}
</script>