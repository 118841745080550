<template>
  <div class="row g-3">
    <div class="identity-content" v-if="isFormSubmitted">
      <span class="icon"><i class="icofont-email"></i></span>
      <p class="big">
        We sent verification email to
        <strong class="text-dark">{{ form.email }}</strong
        >. Click the link inside to proceed for payment!
      </p>
      <!-- <router-link to="/">Go to Dashboard</router-link> -->
    </div>

    <div class="col-12">
      <div class="title-flex">
        <div class="back-btn" @click="$emit('form-step', '1')">
          <svg class="text-main" fill="currentColor" width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1.645L7.28745 0L0 7L7.28745 14L9 12.355L3.43725 7L9 1.645Z"></path>
          </svg>
        </div>
        <h3 class="form-title">Fill your Personal details</h3>
      </div>
      <div class="subtitle-small">
        <p>Please enter your details to create a BuyBit account. If you already have registration, just enter the appropriate email.</p>
      </div>

      <form method="post" @submit.prevent="onSubmit">
        <label class="form-label">Your full name</label>

        <div class="wallet-address-input">
          <input type="text" name="wallet_address" class="form-control" placeholder="Name Surname" v-model="form.name" required />
        </div>
        <label class="form-label">Your email</label>
        <div class="wallet-address-input">
          <input type="text" name="wallet_address" class="form-control" placeholder="john.doe@mail.com" v-model="form.email" required />
        </div>
        <label class="form-label">Your password</label>
        <div class="wallet-address-input">
          <input type="password" name="wallet_address" class="form-control" placeholder="Set a password" v-model="form.password" required />
        </div>

        <p class="text-center text-error">
          {{ error }}
        </p>

        <button type="submit" class="btn btn-primary">Confirm</button>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

export default {
  name: 'BuyFormStep3',
  data: function () {
    return {
      form: {
        name: '',
        email: '',
        password: ''
      },
      error: null,
      isFormSubmitted: false
    }
  },
  methods: {
    onSubmit() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          // console.log(data)

          const dataBase = db.collection('users').doc(data.user.uid)
          // console.log(dataBase)

          dataBase.set({
            name: this.form.name,
            email: this.form.email
          })

          data.user.sendEmailVerification()
        })
        .then(() => {
          localStorage.signUpEmail = this.form.email

          this.isFormSubmitted = true

          console.log(this.isFormSubmitted)

          this.$router.push('/verify-email')
        })
        .catch((err) => {
          this.error = err.code
        })
    }
  }
}
</script>

<style scoped>
.title-flex {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}
.title-flex .form-title {
  margin: 0;
}
.back-btn {
  display: inline-block;
  margin-right: 10px;
  padding: 5px;
  cursor: pointer;
}
.intro-form-exchange .wallet-address-input .form-control {
  font-size: 16px;
  font-weight: 500;
}
.wallet-address-input {
  margin-bottom: 15px;
}
.text-error {
  color: rgb(236, 53, 68);
}
.row {
  position: relative;
}
.identity-content {
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  width: initial;
  max-width: initial;
}
p.big {
  font-size: 16px;
  padding: 0 30px;
}
</style>