<template>
  <div class="mini-logo text-center my-4">
    <router-link to="/">
      <img width="150" src="../../assets/images/svg/buybit-logo-b-h.svg" alt="BitBuy Exchange"/>
    </router-link>
    <!-- <h3 class="card-title mt-3">{{ title }}</h3> -->
  </div>
</template>

<script>
export default {
  name: "AuthHeader",
  props: {
    title: String,
  },
};
</script>

<style scoped>
.mini-logo {
  margin-bottom: 20px;
}
.mini-logo .card-title {
  margin: 30px 0;
}
</style>
