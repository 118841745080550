<template>
  <div id="main-wrapper" class="show landing">
    <div class="header landing bg-primary">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <div class="logo">
                <a href="/">
                  <img height="55" src="../assets/images//svg/buybit-logo-w-h.svg" alt="" class="logo-white" />
                </a>
              </div>
              <div class="intro-btn" v-if="!user.loggedIn">
                <router-link to="/signin" class="btn btn-outline-primary">Login</router-link>
              </div>
              <div class="intro-btn" v-if="user.loggedIn">
                <router-link to="/wallet" class="btn btn-outline-primary">Dashboard</router-link>
              </div>
            </div>
          </div>

          <div class="navigation" style="display: none">
            <nav class="navbar navbar-expand-lg navbar-light">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" href="./index.html">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="./price.html">Price</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="./app.html">App</a>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Company </a>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="./about.html">About us</a>
                      <a class="dropdown-item" href="./team.html">Team</a>
                      <a class="dropdown-item" href="./blog.html">Blog</a>
                      <a class="dropdown-item" href="./career.html">Career</a>
                    </div>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Support </a>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="./contact.html">Contact us</a>
                      <a class="dropdown-item" href="./helpdesk.html">Help Desk</a>
                      <a class="dropdown-item" href="./privacy-policy.html">Privacy</a>
                      <a class="dropdown-item" href="./faq.html">FAQ</a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="./dashboard.html">Dashboard</a>
                  </li>
                </ul>
              </div>

              <div class="signin-btn">
                <a class="btn btn-primary" href="/signin">Sign in</a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="intro bg-primary">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-6 col-12 order-lg-2">
            <div class="intro-form-exchange">
              <BuyForm />
            </div>
          </div>

          <div class="col-lg-6 col-12 order-lg-1">
            <div class="intro-content">
              <h1 class="text-white">
                Buy Bitcoin with
                <br />
                <strong class="text-white">BuyBit One</strong>.
                <br />
                <!-- <small>Easy and convenient cryptocurrency exchange</small> -->
              </h1>
              <p class="text-white">Fast and secure way to purchase <br />Bitcoin with lowest price</p>
              <div class="intro-btn">
                <a href="/signup" class="btn btn-outline-primary">Register Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shape"></div>
    </div>

    <div class="getstart section-padding">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-8">
            <div class="section-title">
              <h2>Get started in a few minutes</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <div class="getstart-content">
              <p class="h1">1</p>
              <h3>Create an account</h3>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <div class="getstart-content">
              <p class="h1">2</p>
              <h3>Link your bank account</h3>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <div class="getstart-content">
              <p class="h1">3</p>
              <h3>Start buying &amp; selling</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="portfolio section-padding bg-gradient-primary">
      <div class="container">
        <div class="row py-lg-5 justify-content-center">
          <div class="col-xl-7">
            <div class="section-title text-center">
              <h2 class="text-white">Create your cryptocurrency portfolio today</h2>
              <p class="text-white">Tradee has a variety of features that make it the best place to start trading</p>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-between">
          <div class="col-xl-4 col-lg-6">
            <div class="portfolio_list">
              <div class="media">
                <span class="port-icon"> <i class="fas fa-user-circle"></i></span>
                <div class="media-body">
                  <h4>Manage your portfolio</h4>
                  <p>Buy and sell popular digital currencies, keep track of them in the one place.</p>
                </div>
              </div>
              <div class="media">
                <span class="port-icon"> <i class="fas fa-shopping-bag"></i></span>
                <div class="media-body">
                  <h4>Recurring buys</h4>
                  <p>Invest in cryptocurrency slowly over time by scheduling buys daily, weekly, or monthly.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="portfolio_img">
              <!-- <img src="../assets/images/portfolio.png" alt="" class="img-fluid"> -->
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="portfolio_list">
              <div class="media">
                <span class="port-icon"> <i class="fas fa-shield-virus"></i></span>
                <div class="media-body">
                  <h4>Vault protection</h4>
                  <p>For added security, store your funds in a vault with time delayed withdrawals.</p>
                </div>
              </div>
              <div class="media">
                <span class="port-icon"> <i class="fas fa-mobile"></i></span>
                <div class="media-body">
                  <h4>Mobile apps</h4>
                  <p>
                    Stay on top of the markets with the Tradee app for
                    <a href="#">Android</a>
                    or
                    <a href="#">iOS</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="trade-app section-padding">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-6">
            <div class="section-title text-center">
              <h2>Trade. Anywhere</h2>
              <p>All of our products are ready to go, easy to use and offer great value to any kind of business</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card trade-app-content">
              <div class="card-body">
                <span><i class="fas fa-mobile"></i></span>
                <h4 class="card-title">Mobile</h4>
                <p>All the power of Tradee's cryptocurrency exchange, in the palm of your hand. Download the Tradee mobile crypto trading app today</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card trade-app-content">
              <div class="card-body">
                <span><i class="fas fa-desktop"></i></span>
                <h4 class="card-title">Desktop</h4>
                <p>Powerful crypto trading platform for those who mean business. The Tradee crypto trading experience, tailor-made for your Windows or MacOS device.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card trade-app-content">
              <div class="card-body">
                <span><i class="fas fa-database"></i></span>
                <h4 class="card-title">API</h4>
                <p>The Tradee API is designed to provide an easy and efficient way to integrate your trading application into our platform.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="brand bg-light section-padding bg-gradient-primary">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="trusted-business pb-5 text-center">
              <h3>Trusted by over <strong>10.000 users</strong></h3>
            </div>
            <div class="row justify-content-center">
              <div class="col-auto">
                <div class="trusted-logo">
                  <a href="https://www.amazon.com/" target="_blank"><img class="img-fluid" src="../assets/images/brand/1.svg" alt="" /></a>
                </div>
              </div>
              <div class="col-auto">
                <div class="trusted-logo">
                  <a href="https://www.spotify.com" target="_blank"><img class="img-fluid" src="../assets/images/brand/2.svg" alt="" /></a>
                </div>
              </div>
              <div class="col-auto">
                <div class="trusted-logo">
                  <a href="https://www.airbnb.com" target="_blank"><img class="img-fluid" src="../assets/images/brand/3.svg" alt="" /></a>
                </div>
              </div>
              <div class="col-auto">
                <div class="trusted-logo">
                  <a href="https://www.paypal.com" target="_blank"><img class="img-fluid" src="../assets/images/brand/4.svg" alt="" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="appss section-padding" style="display: none">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-xl-7 col-lg-6 col-md-6">
            <div class="appss-content">
              <h2>The secure app to store crypto yourself</h2>
              <ul>
                <li><i class="la la-check"></i> All your digital assets in one place</li>
                <li><i class="la la-check"></i> Use Decentralized Apps</li>
                <li><i class="la la-check"></i> Pay friends, not addresses</li>
              </ul>
              <div class="mt-4">
                <a href="#" class="btn btn-primary my-1 waves-effect">
                  <img src="../assets/images/android.svg" alt="" />
                </a>
                <a href="#" class="btn btn-primary my-1 waves-effect">
                  <img src="../assets/images/apple.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="appss-img">
              <img class="img-fluid" src="../assets/images/app.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blog bg-light section-padding" style="display: none">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-6">
            <div class="section-title text-center">
              <h2>Blog</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="blog-grid">
              <div class="card">
                <img class="img-fluid card-img-top" src="../assets/images/blog/1.jpg" alt="" />
                <div class="card-body">
                  <a href="blog-single.html">
                    <h4 class="card-title">Why does Litecoin need MimbleWimble?</h4>
                  </a>
                  <p class="card-text">Cras chinwag brown bread Eaton cracking goal so I said a load of old tosh baking cakes.!</p>
                </div>
                <div class="card-footer">
                  <div class="meta-info">
                    <a href="#" class="author"><img src="../assets/images/avatar/5.jpg" alt="" /> Admin</a>
                    <a href="#" class="post-date"><i class="la la-calendar"></i> 31 July, 2019</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="blog-grid">
              <div class="card">
                <img class="img-fluid card-img-top" src="../assets/images/blog/2.jpg" alt="" />
                <div class="card-body">
                  <a href="blog-single.html">
                    <h4 class="card-title">How to securely store your HD wallet seeds?</h4>
                  </a>
                  <p class="card-text">Cras chinwag brown bread Eaton cracking goal so I said a load of old tosh baking cakes.!</p>
                </div>
                <div class="card-footer">
                  <div class="meta-info">
                    <a href="#" class="author"><img src="../assets/images/avatar/6.jpg" alt="" /> Admin</a>
                    <a href="#" class="post-date"><i class="la la-calendar"></i> 31 July, 2019</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="blog-grid">
              <div class="card">
                <img class="img-fluid card-img-top" src="../assets/images/blog/3.jpg" alt="" />
                <div class="card-body">
                  <a href="blog-single.html">
                    <h4 class="card-title">Exclusive Interview With Xinxi Wang Of Litecoin</h4>
                  </a>
                  <p class="card-text">Cras chinwag brown bread Eaton cracking goal so I said a load of old tosh baking cakes.!</p>
                </div>
                <div class="card-footer">
                  <div class="meta-info">
                    <a href="#" class="author"><img src="../assets/images/avatar/7.jpg" alt="" /> Admin</a>
                    <a href="#" class="post-date"><i class="la la-calendar"></i> 31 July, 2019</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="get-touch section-padding bg-gradient-primary" style="display: none">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-6">
            <div class="section-title">
              <h2>Get in touch. Stay in touch.</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="get-touch-content">
              <span><i class="fas fa-headset"></i></span>
              <h4>24 / 7 Support</h4>
              <p>Got a problem? Just get in touch. Our support team is available 24/7.</p>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="get-touch-content">
              <span><i class="fas fa-blog"></i></span>
              <h4>Tradee Blog</h4>
              <p>News and updates from the world’s leading cryptocurrency exchange.</p>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="get-touch-content">
              <span><i class="fas fa-briefcase"></i></span>
              <h4>Careers</h4>
              <p>Help build the future of technology. Start your new career at Tradee.</p>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="get-touch-content">
              <span><i class="fas fa-users"></i></span>
              <h4>Community</h4>
              <p>Tradee is global. Join the discussion in our worldwide communities.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom section-padding" style="display: none">
      <div class="container">
        <div class="row">
          <div class="col-xl-4">
            <div class="bottom-logo">
              <img class="pb-3" src="../assets/images/logow.png" alt="" />

              <p>
                Tradee is a unique and beautiful collection of UI elements that are all flexible and modular. A complete and customizable solution to building the website of your
                dreams.
              </p>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="bottom-widget">
              <h4 class="widget-title">Company</h4>
              <ul>
                <li><a href="#">About</a></li>
                <li><a href="#">Career</a></li>
                <li><a href="#">Affiliate</a></li>
                <li><a href="#">Our Partner</a></li>
              </ul>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="bottom-widget">
              <h4 class="widget-title">Support</h4>
              <ul>
                <li><a href="#">Ticket</a></li>
                <li><a href="#">FAQ</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Helpdesk</a></li>
              </ul>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="bottom-widget">
              <h4 class="widget-title">Exchange Pair</h4>
              <div class="row">
                <div class="col-xl-6">
                  <ul>
                    <li><a href="#">ETH to BTC</a></li>
                    <li><a href="#">BTC to ETH</a></li>
                    <li><a href="#">LTC to ETH</a></li>
                    <li><a href="#">USDT to BTC</a></li>
                  </ul>
                </div>
                <div class="col-xl-6">
                  <ul>
                    <li><a href="#">BTC to USDT</a></li>
                    <li><a href="#">LTC to BTC</a></li>
                    <li><a href="#">XMR to BTC</a></li>
                    <li><a href="#">ETC to DASH</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BuyForm from '../components/BuyForm/BuyForm.vue'

export default {
  components: { BuyForm },
  mounted: function () {
    document.body.classList = ''
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  }
}
</script>

<style scoped>
body {
  background: #000;
}
</style>  