<template>
  <div id="app">
    <div id="preloader" v-show="isLoading"><i>.</i><i>.</i><i>.</i></div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      isLoading: true,
      time: 100,
      show: 1,
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000)
  }
}
</script>
