<template>
  <div id="main-wrapper" class="show">
    <div class="authincation section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-5 col-md-6">
            <auth-header />

            <div class="auth-form card">
              <div class="identity-content card-hover-message" v-if="isFormSubmitted">
                <span class="icon"><i class="icofont-flag-alt-2"></i></span>
                <p class="big">Your password was successfully reset! <br>Now you can login with your new password</p>
                <router-link class="btn btn-primary btn-block" to="/signin">Go to Sign In</router-link>
              </div>

              <div class="card-body">
                <h3 class="card-title text-center mt-3">Set New Password</h3>
                <p class="text-center text-error">{{ error }}</p>

                <form @submit.prevent="handleResetPassword" class="row g-3">
                  <div class="col-12">
                    <label class="form-label">New password</label>
                    <input type="password" class="form-control" placeholder="********" minlength="6" v-model="form.passwordNew" required />
                  </div>
                  <div class="text-center mt-4">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                  </div>
                </form>
              </div>
            </div>

            <div class="text-center">
              <p class="mt-3 mb-0">Go to <router-link class="text-primary" to="/signin">Sign in</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthHeader from '../../components/qCash/AuthHeader.vue'
import firebase from 'firebase/compat/app'

export default {
  components: { AuthHeader },
  data() {
    return {
      form: {
        passwordNew: ''
      },
      passwordConfirm: '',
      mode: '',
      actionCode: '',
      continueUrl: '',
      isFormSubmitted: false,
      error: ''
    }
  },
  mounted: function () {
    this.actionCode = this.getParameterByName('oobCode');
    // console.log(this.actionCode)
  },
  methods: {
    handleResetPassword: function () {
      let passwordNew = this.passwordNew
      let actionCode = this.actionCode

      firebase
        .auth()
        .verifyPasswordResetCode(actionCode)
        .then(function (email) {
          console.log('Email: ' + email)
          firebase
            .auth()
            .confirmPasswordReset(actionCode, passwordNew)
            .then(function () {
              this.isFormSubmitted = true;
            })
            .catch((err) => {
              console.log(err.code);
              this.error = err.code;
            })
        })
        .catch((err) => {
          this.error = err.code;
          console.log(err.code);
          //this.error = "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.";
        })
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[[]]/g, '\\$&')
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, ' '))
    }
  }
}
</script>
