<template>
  <div style="height: 210px">
    <apexchart
      type="donut"
      height="210"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import apexchart from "vue-apexcharts";

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '../../initFirebase'

export default {
  name: "BalanceChart",
  components: { apexchart },
  data() {
    return {
      series: [1,1,1,1],
      balances: {
        available: 0,
        estimated: 0,
        pending: 0,
        locked: 0,
      },
      chartOptions: {
        chart: {
          height: 210,
          type: "donut",
        },
        dataLabels: { 
          enabled: false,
        },
        labels: ["Available", "Estimated", "Pending", "Locked"],
        fill: {
          colors: ["#34c38f", "#556ee6", "#f1b457", "#f46a6a"],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          show: false,
          position: "right",
          offsetY: 0,
          height: 150,
        },
      },
    };
  },
  created() {
    db.collection('users')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot((doc) => {
        if (doc.data().balances && doc.data().balances.available !== 0) {

          // console.log(doc.data().balances)
          this.balances.available = doc.data().balances.available
          this.balances.estimated = doc.data().balances.estimated
          this.balances.pending = doc.data().balances.pending
          this.balances.locked = doc.data().balances.locked

          this.series = Object.values(this.balances);
        }


      })
  },
};
</script>
