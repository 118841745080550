<template>
  <layout>
    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-xxl-8 col-xl-8">
            <div class="card">
              <div class="card-body">
                <div class="this-coin-price">
                  <h3>
                    1,145,062.90
                    <sub>USD</sub>
                  </h3>
                  <span class="text-danger"
                    >-0.2.30% <i class="icofont-arrow-down"></i
                  ></span>
                </div>
                <!-- <div id="btcChart"></div> -->
                <btc-chart />
                <div class="chart-content text-center">
                  <div class="row">
                    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="chart-stat">
                        <p class="mb-1">24hr Volume</p>
                        <h5>$1236548.325</h5>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="chart-stat">
                        <p class="mb-1">Market Cap</p>
                        <h5>19B USD</h5>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="chart-stat">
                        <p class="mb-1">Circulating Supply</p>
                        <h5>29.4M BTC</h5>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="chart-stat">
                        <p class="mb-1">All Time High</p>
                        <h5>19.783.06 USD</h5>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="chart-stat">
                        <p class="mb-1">Typical hold time</p>
                        <h5>88 days</h5>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="chart-stat">
                        <p class="mb-1">Trading activity</p>
                        <h5>70% buy</h5>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="chart-stat">
                        <p class="mb-1">Popularity</p>
                        <h5>#1 most held</h5>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="chart-stat">
                        <p class="mb-1">Popularity</p>
                        <h5>#1 most held</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Price correlation with</h4>
              </div>
              <div class="card-body">
                <ul class="balance-widget">
                  <li>
                    <div class="icon-title">
                      <i class="cc BTC"></i>
                      <span
                        >Bitcoin <br />
                        <small>Moves together</small></span
                      >
                    </div>
                    <div class="text-end">
                      <h5>0.000242 USD</h5>
                      <span>64% </span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-title">
                      <i class="cc LTC"></i>
                      <span
                        >Litecoin <br />
                        <small>Moves together</small></span
                      >
                    </div>
                    <div class="text-end">
                      <h5>0.000242 USD</h5>
                      <span>0.125 %</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-title">
                      <i class="cc XRP"></i>
                      <span
                        >Ripple <br />
                        <small>Moves together</small></span
                      >
                    </div>
                    <div class="text-end">
                      <h5>0.000242 USD</h5>
                      <span>0.125 %</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-title">
                      <i class="cc DASH"></i>
                      <span
                        >Dash <br />
                        <small>Moves together</small></span
                      >
                    </div>
                    <div class="text-end">
                      <h5>0.000242 USD</h5>
                      <span>0.125 %</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-title">
                      <i class="cc XRP"></i>
                      <span
                        >Ripple <br />
                        <small>Moves together</small></span
                      >
                    </div>
                    <div class="text-end">
                      <h5>0.000242 USD</h5>
                      <span>0.125 %</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-title">
                      <i class="cc DASH"></i>
                      <span
                        >Dash <br />
                        <small>Moves together</small></span
                      >
                    </div>
                    <div class="text-end">
                      <h5>0.000242 USD</h5>
                      <span>0.125 %</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-title">
                      <i class="cc DASH"></i>
                      <span
                        >Dash <br />
                        <small>Moves together</small></span
                      >
                    </div>
                    <div class="text-end">
                      <h5>0.000242 USD</h5>
                      <span>0.125 %</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-title">
                      <i class="cc DASH"></i>
                      <span
                        >Dash <br />
                        <small>Moves together</small></span
                      >
                    </div>
                    <div class="text-end">
                      <h5>0.000242 USD</h5>
                      <span>0.125 %</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="invite-content">
                  <h4>Invite a friend and get $30</h4>
                  <p>
                    Know someone curious about crypto? You will receive up to
                    $30 when they： 1.Buy Crypto 2. Deposit 3. Finish Trading
                    Tasks <br />
                    <a href="#">Learn more</a>
                  </p>

                  <div class="copy-link">
                    <form @submit.prevent="">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          :value="message1"
                        />
                        <span
                          class="input-group-text c-pointer"
                          @click="doCopy1"
                          >Copy</span
                        >
                      </div>
                    </form>
                  </div>

                  <div class="social-share-link">
                    <a href="#"
                      ><span><i class="icofont-facebook"></i></span
                    ></a>
                    <a href="#"
                      ><span><i class="icofont-twitter"></i></span
                    ></a>
                    <a href="#"
                      ><span><i class="icofont-whatsapp"></i></span
                    ></a>
                    <a href="#"
                      ><span><i class="icofont-telegram"></i></span
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Deposit</h4>
              </div>
              <div class="card-body">
                <div class="price-deposit">
                  <form @submit.prevent="">
                    <label class="form-label">BTC Deposit Address</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        :value="message2"
                      />
                      <span class="input-group-text c-pointer" @click="doCopy2"
                        >Copy</span
                      >
                    </div>
                  </form>
                </div>
                <div class="mt-4">
                  <a class="btn btn-primary btn-block c-pointer"
                    >Withdraw BTC</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Buy</h4>
              </div>
              <div class="card-body">
                <form
                  method="post"
                  @submit.prevent=""
                  name="myform"
                  class="currency_validate"
                >
                  <label class="form-label">Buy BTC</label>
                  <div class="input-group">
                    <input
                      type="text"
                      name="currency_amount"
                      class="form-control"
                      placeholder="0.0214 BTC"
                    />
                    <select class="form-control" name="method">
                      <option value="bank">USD</option>
                      <option value="master">Euro</option>
                    </select>
                  </div>

                  <button
                    type="submit"
                    name="submit"
                    class="btn btn-success btn-block mt-4"
                  >
                    Buy Now
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Sell</h4>
              </div>
              <div class="card-body">
                <form
                  method="post"
                  @submit.prevent=""
                  name="myform"
                  class="currency_validate"
                >
                  <label class="form-label">Sell BTC</label>
                  <div class="input-group">
                    <input
                      type="text"
                      name="currency_amount"
                      class="form-control"
                      placeholder="0.0214 BTC"
                    />
                    <select class="form-control" name="method">
                      <option value="bank">USD</option>
                      <option value="master">Euro</option>
                    </select>
                  </div>

                  <button
                    type="submit"
                    name="submit"
                    class="btn btn-success btn-block mt-4"
                  >
                    Sell Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import BtcChart from "../../components/qCash/BtcChart.vue";
import Layout from "../../components/qCash/Layout.vue";

export default {
  components: { Layout, BtcChart },
  data() {
    return {
      message1: "https://www.BuyBit.io/join/12345",
      message2: "35Hb5B6qJa5ntYaNFN3hGYXdAjh919g2VH",
    };
  },
  methods: {
    doCopy1: function () {
      this.$copyText(this.message1).then(
        function () {
          alert("Copied");
        },
        function () {
          alert("Can not copy");
        }
      );
    },
    doCopy2: function () {
      this.$copyText(this.message2).then(
        function () {
          alert("Copied");
        },
        function () {
          alert("Can not copy");
        }
      );
    },
  },
};
</script>
